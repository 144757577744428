import React, { useState } from 'react';
import { format } from 'date-fns';
import { Edit, X, ArrowLeft, Scissors, Truck } from 'lucide-react';
import { Table, TableCell, TableHeader, TableRow } from '@lib/components/Table';
import { ActionButton } from '../../components/MainDashboard/styles';
import { 
  TableWrapper,
  StatusBadge,
  CheckboxCell,
  AssignButton,
  ActionCell
} from './styles';

const DashboardTables = ({
  pallets,
  selectedPallets,
  selectAllChecked,
  onSelectAll,
  onSelectPallet,
  onSort,
  onShip,
  onEdit,
  onDelete,
  onReturn,
  onSplit,
  onAssignOrder,
  onRowKeyDown,
}) => {
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);

  const handleSort = (field) => {
    let newDirection = 'asc';
    if (sortField === field) {
      if (sortDirection === 'asc') {
        newDirection = 'desc';
      } else if (sortDirection === 'desc') {
        newDirection = null;
      }
    }
    setSortField(newDirection ? field : null);
    setSortDirection(newDirection);
    onSort(field, newDirection);
  };

  const getProcessStageBadgeStatus = (stage) => {
    switch (stage) {
      case 'unprocessed': return 'warning';
      case 'in_processing': return 'info';
      case 'processed': return 'success';
      case 'quality_check': return 'warning';
      case 'ready_for_shipping': return 'success';
      case 'returned': return 'error';
      default: return 'default';
    }
  };

  return (
    <TableWrapper>
      <Table hover>
        <thead>
          <tr>
            <TableHeader width="40px" align="center">
              <CheckboxCell>
                <input
                  type="checkbox"
                  checked={selectAllChecked}
                  onChange={(e) => onSelectAll(e.target.checked)}
                  data-testid="select-all-checkbox"
                />
              </CheckboxCell>
            </TableHeader>
            <TableHeader 
              sortable 
              onClick={() => handleSort('tomatoType')} 
              sortDirection={sortField === 'tomatoType' ? sortDirection : undefined}
              minWidth="150px"
            >
              Type/Option
            </TableHeader>
            <TableHeader 
              sortable 
              onClick={() => handleSort('boxType')} 
              sortDirection={sortField === 'boxType' ? sortDirection : undefined}
              minWidth="150px"
            >
              Box/Pallet
            </TableHeader>
            <TableHeader 
              sortable 
              onClick={() => handleSort('processStage')} 
              sortDirection={sortField === 'processStage' ? sortDirection : undefined}
              minWidth="140px"
            >
              Process Stage
            </TableHeader>
            <TableHeader 
              sortable 
              onClick={() => handleSort('colliCount')} 
              sortDirection={sortField === 'colliCount' ? sortDirection : undefined}
              minWidth="100px"
              align="right"
            >
              Boxes
            </TableHeader>
            <TableHeader 
              sortable 
              onClick={() => handleSort('weight')} 
              sortDirection={sortField === 'weight' ? sortDirection : undefined}
              minWidth="100px"
              align="right"
            >
              Net Weight
            </TableHeader>
            <TableHeader 
              sortable 
              onClick={() => handleSort('status')} 
              sortDirection={sortField === 'status' ? sortDirection : undefined}
              minWidth="120px"
            >
              Status
            </TableHeader>
            <TableHeader 
              sortable 
              onClick={() => handleSort('orderCustomer')} 
              sortDirection={sortField === 'orderCustomer' ? sortDirection : undefined}
              minWidth="150px"
            >
              Order
            </TableHeader>
            <TableHeader minWidth="120px" align="center">
              Assign to Order
            </TableHeader>
            <TableHeader 
              sortable 
              onClick={() => handleSort('addedDate')} 
              sortDirection={sortField === 'addedDate' ? sortDirection : undefined}
              minWidth="180px"
            >
              Added
            </TableHeader>
            <TableHeader width="180px" align="right">
              Actions
            </TableHeader>
          </tr>
        </thead>
        <tbody>
          {pallets.map(pallet => (
            <TableRow
              key={pallet.id}
              selected={selectedPallets?.has(pallet.id)}
              onKeyDown={(e) => onRowKeyDown?.(e, pallet.id)}
              tabIndex={0}
            >
              <TableCell align="center">
                <CheckboxCell>
                  <input
                    type="checkbox"
                    checked={selectedPallets?.has(pallet.id)}
                    onChange={() => onSelectPallet(pallet.id)}
                  />
                </CheckboxCell>
              </TableCell>
              <TableCell>
                <div style={{ fontWeight: 500 }}>
                  {pallet.tomatoType || '-'}
                </div>
                <div style={{ color: '#6B7280', fontSize: '0.875rem' }}>
                  {pallet.tomatoOption || '-'}
                </div>
              </TableCell>
              <TableCell>
                <div style={{ fontWeight: 500 }}>{pallet.boxType}</div>
                <div style={{ color: '#6B7280', fontSize: '0.875rem' }}>{pallet.palletType}</div>
              </TableCell>
              <TableCell>
                <StatusBadge status={getProcessStageBadgeStatus(pallet.processStage)}>
                  {pallet.processStage
                    ? pallet.processStage.charAt(0).toUpperCase() + 
                      pallet.processStage.slice(1).replace(/_/g, ' ')
                    : 'Unprocessed'}
                </StatusBadge>
              </TableCell>
              <TableCell align="right">
                {pallet.colliCount ? `${pallet.colliCount} boxes` : '-'}
              </TableCell>
              <TableCell align="right">
                {pallet.weight 
                  ? <span style={{ fontWeight: 500 }}>{parseFloat(pallet.weight).toFixed(2)} kg</span>
                  : '-'}
              </TableCell>
              <TableCell>
                <StatusBadge status={pallet.status || 'default'}>
                  {pallet.status 
                    ? pallet.status.charAt(0).toUpperCase() + pallet.status.slice(1)
                    : 'Unknown'}
                </StatusBadge>
              </TableCell>
              <TableCell>
                {pallet.orderCustomer && (
                  <div>
                    <StatusBadge status={pallet.status === 'shipped' ? 'shipped' : 'assigned'}>
                      {typeof pallet.orderCustomer === 'string' ? pallet.orderCustomer : 
                       typeof pallet.orderCustomer === 'object' ? 
                       (pallet.orderCustomer.name || pallet.orderCustomer.id || 'Unknown Customer') : 
                       String(pallet.orderCustomer)}
                    </StatusBadge>
                    {pallet.order_is_completed && (
                      <StatusBadge status="completed" style={{ marginLeft: '0.5rem' }}>
                        Completed
                      </StatusBadge>
                    )}
                  </div>
                )}
              </TableCell>
              <TableCell align="center">
                <AssignButton
                  onClick={() => onAssignOrder([pallet.id])}
                  disabled={pallet.status === 'shipped'}
                >
                  Assign to Order
                </AssignButton>
              </TableCell>
              <TableCell>
                <div>{pallet.addedDate ? format(new Date(pallet.addedDate), 'MMM d, yyyy') : 'Unknown'}</div>
                <div style={{ color: '#6B7280', fontSize: '0.75rem' }}>
                  Added by: {pallet.userName || 'Unknown'}
                  {pallet.lastEditor && pallet.lastEditor !== pallet.userName && (
                    <div style={{ marginTop: '0.25rem' }}>
                      Edited by: {pallet.lastEditor}
                    </div>
                  )}
                </div>
                {pallet.status === 'shipped' && (
                  <div style={{ color: '#6B7280', fontSize: '0.75rem', marginTop: '0.25rem' }}>
                    Shipped: {pallet.shipped_date ? format(new Date(pallet.shipped_date), 'MMM d, yyyy') : 'Unknown'}
                    <br />
                    By: {pallet.shipped_by}
                  </div>
                )}
              </TableCell>
              <TableCell align="right">
                <ActionCell>
                  {pallet.status !== 'shipped' && (
                    <>
                      <ActionButton variant="edit" onClick={() => onEdit(pallet)}>
                        <Edit size={16} />
                      </ActionButton>
                      {pallet.order_id && (
                        <ActionButton variant="return" onClick={() => onReturn(pallet)}>
                          <ArrowLeft size={16} />
                        </ActionButton>
                      )}
                      {pallet.colliCount > 0 && (
                        <ActionButton variant="split" onClick={() => onSplit(pallet)}>
                          <Scissors size={16} />
                        </ActionButton>
                      )}
                      <ActionButton variant="ship" onClick={() => onShip(pallet.id)}>
                        <Truck size={16} />
                      </ActionButton>
                      <ActionButton variant="delete" onClick={() => onDelete(pallet.id)}>
                        <X size={16} />
                      </ActionButton>
                    </>
                  )}
                </ActionCell>
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </TableWrapper>
  );
};

export default DashboardTables;
