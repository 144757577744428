import axios from 'axios';
import { handleApiError } from '@lib/utils/errorHandler';
import { hasValidTokens } from '@features/auth/services/authService';

const instance = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? 'https://eyesu.ltd' : '',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
});

let isRefreshing = false;
let refreshPromise = null;
let failedQueue = [];
let lastRequestTime = {};

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

const clearAuthData = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('userData');
};

const debounceRequest = (config) => {
  const key = `${config.method}-${config.url}`;
  const now = Date.now();
  const minInterval = 1000; // 1 second minimum between same requests

  if (lastRequestTime[key] && (now - lastRequestTime[key] < minInterval)) {
    return true;
  }

  lastRequestTime[key] = now;
  return false;
};

instance.interceptors.request.use(
  config => {
    // Check for rapid duplicate requests
    if (debounceRequest(config)) {
      return Promise.reject(new Error('Request debounced'));
    }

    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => Promise.reject(error)
);

instance.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;

    // Handle debounced requests gracefully
    if (error.message === 'Request debounced') {
      return new Promise(resolve => setTimeout(resolve, 1000))
        .then(() => instance(originalRequest));
    }

    if (error.response?.status === 401 && !originalRequest._retry) {
      // Check if we still have valid tokens before attempting refresh
      if (!hasValidTokens()) {
        clearAuthData();
        window.location.replace('/login');
        return Promise.reject(error);
      }

      if (isRefreshing) {
        try {
          const token = await refreshPromise;
          originalRequest.headers.Authorization = `Bearer ${token}`;
          return instance(originalRequest);
        } catch (err) {
          return Promise.reject(err);
        }
      }

      originalRequest._retry = true;
      isRefreshing = true;

      refreshPromise = (async () => {
        try {
          const refreshToken = localStorage.getItem('refreshToken');
          if (!refreshToken) {
            throw new Error('No refresh token available');
          }

          const response = await instance.post('/api/auth/refresh-token', {
            refreshToken
          });

          const { token } = response.data;
          localStorage.setItem('token', token);
          processQueue(null, token);
          return token;
        } catch (refreshError) {
          processQueue(refreshError, null);
          clearAuthData();
          window.location.replace('/login');
          throw refreshError;
        } finally {
          isRefreshing = false;
          refreshPromise = null;
        }
      })();

      try {
        const token = await refreshPromise;
        originalRequest.headers.Authorization = `Bearer ${token}`;
        return instance(originalRequest);
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
    }

    // Clear request tracking on error
    if (originalRequest) {
      const key = `${originalRequest.method}-${originalRequest.url}`;
      delete lastRequestTime[key];
    }

    return Promise.reject(handleApiError(error));
  }
);

export default instance;
