import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledTableHeader = styled.th`
  padding: ${({ theme }) => theme.spacing.md};
  text-align: left;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.light};
  background-color: ${({ theme }) => theme.colors.background.default};
  font-weight: 600;
  color: ${({ theme }) => theme.colors.text.secondary};
  user-select: none;
  white-space: nowrap;
  cursor: ${({ sortable }) => sortable ? 'pointer' : 'default'};
  transition: ${({ theme }) => theme.transitions.default};

  &:hover {
    background-color: ${({ sortable, theme }) => 
      sortable ? theme.colors.background.hover : theme.colors.background.default};
  }

  &[data-sortable="true"] {
    position: relative;
    padding-right: ${({ theme }) => theme.spacing.xl};

    &::after {
      content: '';
      position: absolute;
      right: ${({ theme }) => theme.spacing.md};
      top: 50%;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-bottom: 4px solid ${({ theme }) => theme.colors.text.secondary};
      opacity: 0.5;
      transition: ${({ theme }) => theme.transitions.default};
    }

    &[data-sort-direction="desc"]::after {
      transform: translateY(-50%) rotate(180deg);
    }

    &:hover::after {
      opacity: 1;
      border-bottom-color: ${({ theme }) => theme.colors.primary.main};
    }
  }
`;

const TableHeader = ({ 
  children, 
  className, 
  onClick,
  sortable,
  sortDirection,
  style
}) => {
  return (
    <StyledTableHeader 
      className={className} 
      onClick={sortable ? onClick : undefined}
      sortable={sortable}
      data-sortable={sortable}
      data-sort-direction={sortDirection}
      role="columnheader"
      aria-sort={sortable ? (sortDirection || "none") : undefined}
      style={style}
    >
      {children}
    </StyledTableHeader>
  );
};

TableHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  sortable: PropTypes.bool,
  sortDirection: PropTypes.oneOf(['asc', 'desc']),
  style: PropTypes.object
};

TableHeader.defaultProps = {
  sortable: false
};

export default TableHeader;
