import React, { useState, useContext } from 'react';
import { Alert, AlertDescription } from '@lib/components/ui';
import { UserContext } from '@lib/contexts';
import { Check, AlertTriangle, Loader } from 'lucide-react';
import styled from 'styled-components';
import { 
  palletTypes, 
  boxTypes, 
  tomatoOptions,
  tomatoTypes,
  processStages 
} from '@lib/constants';

const FormContainer = styled.div`
  max-width: 48rem;
  margin: 0 auto;
  padding: ${({ theme }) => theme.spacing.lg};
  background-color: ${({ theme }) => theme.colors.background.paper};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  box-shadow: ${({ theme }) => theme.shadows.md};
`;

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${({ theme }) => theme.spacing.md};
  
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const FormGroup = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

const Label = styled.label`
  display: block;
  ${({ theme }) => theme.typography.body2};
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-bottom: ${({ theme }) => theme.spacing.xs};
`;

const Input = styled.input`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.md};
  border: 1px solid ${({ hasError, theme }) => 
    hasError ? theme.colors.error : theme.colors.border.main};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  ${({ theme }) => theme.typography.body1};
  background-color: ${({ theme }) => theme.colors.background.paper};
  transition: ${({ theme }) => theme.transitions.default};

  &:hover {
    border-color: ${({ hasError, theme }) => 
      hasError ? theme.colors.error : theme.colors.primary.light};
  }

  &:focus {
    outline: none;
    border-color: ${({ hasError, theme }) => 
      hasError ? theme.colors.error : theme.colors.primary.main};
    box-shadow: 0 0 0 2px ${({ hasError, theme }) => 
      hasError ? `${theme.colors.error}20` : `${theme.colors.primary.main}20`};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.background.darker};
    cursor: not-allowed;
  }
`;

const Select = styled(Input).attrs({ as: 'select' })`
  cursor: pointer;
  
  &:not([value=""]) {
    color: ${({ theme }) => theme.colors.text.primary};
  }
  
  &[value=""] {
    color: ${({ theme }) => theme.colors.text.secondary};
  }
`;

const ErrorText = styled.p`
  ${({ theme }) => theme.typography.caption};
  color: ${({ theme }) => theme.colors.error};
  margin-top: ${({ theme }) => theme.spacing.xs};
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing.md};
  margin-top: ${({ theme }) => theme.spacing.xl};
`;

const Button = styled.button`
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.lg}`};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  transition: ${({ theme }) => theme.transitions.default};
  ${({ theme }) => theme.typography.body2};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
  
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  ${({ variant, theme }) => {
    switch (variant) {
      case 'primary':
        return `
          background-color: ${theme.colors.primary.main};
          color: ${theme.colors.common.white};
          &:hover:not(:disabled) {
            background-color: ${theme.colors.primary.dark};
          }
        `;
      default:
        return `
          background-color: ${theme.colors.background.paper};
          border: 1px solid ${theme.colors.border.main};
          color: ${theme.colors.text.primary};
          &:hover:not(:disabled) {
            background-color: ${theme.colors.background.hover};
          }
        `;
    }
  }}
`;

export const PalletForm = ({
  onPalletAdded,
  onCancel,
  tomatoType,
  defaultProcessStage = 'unprocessed',
  initialData = null,
  locationId,
}) => {
  const { user } = useContext(UserContext);
  const [formData, setFormData] = useState({
    palletType: initialData?.palletType || '',
    boxType: initialData?.boxType || '',
    processStage: initialData?.processStage || defaultProcessStage,
    tomatoOption: initialData?.tomatoOption || '',
    tomatoType: initialData?.tomatoType || tomatoType || 'Round',
    weight: initialData?.weight || '',
    order_id: initialData?.order_id || '',
    quantity: initialData?.quantity || 1,
    colliCount: initialData?.colliCount || '',
  });

  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [errors, setErrors] = useState({});

  const validateField = (name, value) => {
    switch (name) {
      case 'palletType':
        return !value ? 'Pallet type is required' : '';
      case 'boxType':
        return !value ? 'Box type is required' : '';
      case 'quantity':
        const qty = parseInt(value);
        return (!qty || qty < 1) ? 'Quantity must be at least 1' : '';
      default:
        return '';
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    
    const error = validateField(name, value);
    if (error) {
      setErrors(prev => ({
        ...prev,
        [name]: error
      }));
    } else {
      setErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors[name];
        return newErrors;
      });
    }
  };

  const handleSubmit = async () => {
    const newErrors = {};
    Object.keys(formData).forEach(key => {
      const error = validateField(key, formData[key]);
      if (error) newErrors[key] = error;
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setFeedback({
        type: 'error',
        message: 'Please fill in all required fields'
      });
      return;
    }

    setLoading(true);
    try {
      await onPalletAdded({
        ...formData,
        locationId,
        userName: user.username,
        user_id: user.id,
      });
      setFeedback({
        type: 'success',
        message: 'Pallet added successfully'
      });
    } catch (error) {
      setFeedback({
        type: 'error',
        message: error.message || 'Failed to add pallet'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormContainer>
      {feedback && (
        <Alert className={`mb-4 ${feedback.type === 'error' ? 'bg-red-50' : 'bg-green-50'}`}>
          {feedback.type === 'error' ? (
            <AlertTriangle className="h-4 w-4" />
          ) : (
            <Check className="h-4 w-4" />
          )}
          <AlertDescription>{feedback.message}</AlertDescription>
        </Alert>
      )}
      
      <FormGrid>
        <FormGroup>
          <Label>Tomato Type</Label>
          <Select
            name="tomatoType"
            value={formData.tomatoType}
            onChange={handleInputChange}
            hasError={!!errors.tomatoType}
          >
            <option value="">Select Tomato Type</option>
            {Object.keys(tomatoOptions).map(type => (
              <option key={type} value={type}>{type}</option>
            ))}
          </Select>
          {errors.tomatoType && <ErrorText>{errors.tomatoType}</ErrorText>}
        </FormGroup>

        {Object.keys(tomatoOptions[formData.tomatoType] || {}).length > 0 && (
          <FormGroup>
            <Label>Tomato Option</Label>
            <Select
              name="tomatoOption"
              value={formData.tomatoOption}
              onChange={handleInputChange}
              hasError={!!errors.tomatoOption}
              disabled={!formData.tomatoType}
            >
              <option value="">Select Tomato Option</option>
              {Object.keys(tomatoOptions[formData.tomatoType] || {}).map(option => (
                <option key={option} value={option}>{option}</option>
              ))}
            </Select>
            {errors.tomatoOption && <ErrorText>{errors.tomatoOption}</ErrorText>}
          </FormGroup>
        )}

        <FormGroup>
          <Label>Pallet Type</Label>
          <Select
            name="palletType"
            value={formData.palletType}
            onChange={handleInputChange}
            hasError={!!errors.palletType}
          >
            <option value="">Select Pallet Type</option>
            {palletTypes.map(({ type }) => (
              <option key={type} value={type}>{type}</option>
            ))}
          </Select>
          {errors.palletType && <ErrorText>{errors.palletType}</ErrorText>}
        </FormGroup>

        <FormGroup>
          <Label>Box Type</Label>
          <Select
            name="boxType"
            value={formData.boxType}
            onChange={handleInputChange}
            hasError={!!errors.boxType}
          >
            <option value="">Select Box Type</option>
            {boxTypes.map(type => (
              <option key={type} value={type}>{type}</option>
            ))}
          </Select>
          {errors.boxType && <ErrorText>{errors.boxType}</ErrorText>}
        </FormGroup>

        <FormGroup>
          <Label>Weight (kg)</Label>
          <Input
            type="number"
            name="weight"
            value={formData.weight}
            onChange={handleInputChange}
            hasError={!!errors.weight}
            placeholder="Enter pallet weight"
            step="0.1"
            min="0"
            max="2000"
          />
          {errors.weight && <ErrorText>{errors.weight}</ErrorText>}
        </FormGroup>

        <FormGroup>
          <Label>Pallet Count</Label>
          <Input
            type="number"
            name="quantity"
            value={formData.quantity}
            onChange={handleInputChange}
            hasError={!!errors.quantity}
            placeholder="Number of pallets"
            min="1"
            max="50"
          />
          {errors.quantity && <ErrorText>{errors.quantity}</ErrorText>}
        </FormGroup>

        <FormGroup>
          <Label>Box Count (Colli)</Label>
          <Input
            type="number"
            name="colliCount"
            value={formData.colliCount}
            onChange={handleInputChange}
            hasError={!!errors.colliCount}
            placeholder="Number of boxes on pallet"
            min="0"
            max="200"
          />
          {errors.colliCount && <ErrorText>{errors.colliCount}</ErrorText>}
        </FormGroup>

        <FormGroup>
          <Label>Process Stage</Label>
          <Select
            name="processStage"
            value={formData.processStage}
            onChange={handleInputChange}
            hasError={!!errors.processStage}
          >
            <option value="">Select Process Stage</option>
            {processStages.map(stage => (
              <option key={stage} value={stage}>
                {stage.charAt(0).toUpperCase() + stage.slice(1).replace(/_/g, ' ')}
              </option>
            ))}
          </Select>
          {errors.processStage && <ErrorText>{errors.processStage}</ErrorText>}
        </FormGroup>
      </FormGrid>

      <ButtonGroup>
        <Button onClick={onCancel} disabled={loading}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleSubmit}
          disabled={loading || Object.keys(errors).length > 0}
        >
          {loading ? (
            <>
              <Loader className="animate-spin" size={16} />
              Processing...
            </>
          ) : (
            initialData ? 'Update Pallet' : 'Add Pallet'
          )}
        </Button>
      </ButtonGroup>
    </FormContainer>
  );
};

export default PalletForm;
