import React, { createContext, useContext, useState, useCallback } from 'react';
import { Processing } from '@lib/components/ui';
import { useLoading } from '@lib/contexts/LoadingContext';

const ProcessingContext = createContext(null);

export const ProcessingProvider = ({ children }) => {
  const [processing, setProcessing] = useState(null);
  const { showLoading, hideLoading } = useLoading();

  const showProcessing = useCallback(({ message = 'Processing...', showOverlay = true }) => {
    setProcessing(message);
    if (showOverlay) {
      showLoading(message);
    }
  }, [showLoading]);

  const hideProcessing = useCallback(() => {
    setProcessing(null);
    hideLoading();
  }, [hideLoading]);

  return (
    <ProcessingContext.Provider value={{ showProcessing, hideProcessing }}>
      {children}
      {processing && <Processing message={processing} />}
    </ProcessingContext.Provider>
  );
};

export const useProcessing = () => {
  const context = useContext(ProcessingContext);
  if (!context) {
    throw new Error('useProcessing must be used within a ProcessingProvider');
  }
  return context;
};
