import React from 'react';
import { OrderInfo } from '../styles';

const PalletDetails = ({ pallet }) => {
  if (!pallet) return null;

  return (
    <OrderInfo style={{ marginBottom: '1rem' }}>
      <h4>Selected Pallet</h4>
      <p>Type: {pallet.tomatoType}</p>
      <p>Box Type: {pallet.boxType}</p>
      <p>Weight: {pallet.weight}kg</p>
    </OrderInfo>
  );
};

export default PalletDetails;
