// Define tomato types as a simple array of strings for the dropdown
export const tomatoTypes = ['Round', 'Cherry Round', 'Plum', 'Cherry Plum', 'Trostomaten'];

// Keep the detailed options for other uses
export const tomatoOptions = {
  'Round': {
    'A grof +': {},
    'A grof': {},
    'A fijn': {},
    'B': {}
  },
  'Cherry Round': {
    'A': {},
    'B': {},
  },
  'Plum': {
    '6-Pack': {},
    '5-Pack': {},
    'A fijn': {},
    'B': {}
  },
  'Cherry Plum': {
    'A': {},
    'B': {},
  },
  'Trostomaten': {
    'A': {},
    'B': {},
    'Mix': {}
  }
};
