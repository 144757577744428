import React, { useState } from 'react';
import { Container, Header, ToggleButton, SelectionGrid, SelectionCell } from './styles';
import { AddPalletMobile } from '../../AddPallet';

export const SelectionMenu = ({
  selectedPallets,
  onPalletSelect,
  tomatoType,
  onToggleTomatoType
}) => {
  const [showAddPallet, setShowAddPallet] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState(null);

  const getPositionInfo = (position) => {
    const positions = getPositionsForTomatoType(tomatoType);
    return positions.find(pos => pos.label === position) || null;
  };

  const handlePalletSelect = (position) => {
    const positionInfo = getPositionInfo(position);
    if (!positionInfo) return;

    setSelectedPosition(position);
    setShowAddPallet(true);
  };

  const handleAddPallet = (palletData) => {
    const positionInfo = getPositionInfo(selectedPosition);
    onPalletSelect(selectedPosition, {
      ...palletData,
      tomatoType,
      processStage: positionInfo.processStage,
      tomatoOption: positionInfo.label
    });
    setShowAddPallet(false);
    setSelectedPosition(null);
  };

  const handleCloseModal = () => {
    setShowAddPallet(false);
    setSelectedPosition(null);
  };

  const renderPositionButton = (position) => {
    const isSelected = !!selectedPallets[position.label];

    return (
      <SelectionCell
        key={position.label}
        onClick={() => handlePalletSelect(position.label)}
        isSelected={isSelected}
      >
        {position.label}
      </SelectionCell>
    );
  };

  const positions = getPositionsForTomatoType(tomatoType);
  const selectedPositionInfo = selectedPosition ? getPositionInfo(selectedPosition) : null;

  return (
    <Container>
      <Header>
        <h2>Selection</h2>
        <ToggleButton onClick={onToggleTomatoType}>
          Switch to {tomatoType === 'Round' ? 'Plum' : 'Round'}
        </ToggleButton>
      </Header>

      <SelectionGrid>
        {positions.slice(0, 6).map(renderPositionButton)}
      </SelectionGrid>
      
      <SelectionGrid>
        {positions.slice(6, 8).map(renderPositionButton)}
      </SelectionGrid>

      <h3>Automaat</h3>
      
      <SelectionGrid>
        {positions.slice(8).map(renderPositionButton)}
      </SelectionGrid>

      {selectedPositionInfo && (
        <AddPalletMobile
          isOpen={showAddPallet}
          onPalletAdded={handleAddPallet}
          onCancel={handleCloseModal}
          position={selectedPosition}
          tomatoType={tomatoType}
          processStage={selectedPositionInfo.processStage}
          tomatoOption={selectedPositionInfo.label}
        />
      )}
    </Container>
  );
};

const getPositionsForTomatoType = (tomatoType) => {
  return tomatoType === 'Round' 
    ? [
        { label: 'Light A grof +', processStage: 'Light' },
        { label: 'Middle A grof +', processStage: 'Middle' },
        { label: 'Red A grof +', processStage: 'Red' },
        { label: 'Light B', processStage: 'Light' },
        { label: 'Middle B', processStage: 'Middle' },
        { label: 'Red B', processStage: 'Red' },
        { label: 'A fijn Light', processStage: 'Light' },
        { label: 'A fijn Middle', processStage: 'Middle' },
        { label: 'A grof Red', processStage: 'Red' },
        { label: 'A grof Middle', processStage: 'Middle' },
        { label: 'A grof Light', processStage: 'Light' },
      ]
    : [
        { label: 'Light 6 Pack', processStage: 'Light' },
        { label: 'Middle 6 Pack', processStage: 'Middle' },
        { label: 'Red 6 Pack', processStage: 'Red' },
        { label: 'Light B', processStage: 'Light' },
        { label: 'Middle B', processStage: 'Middle' },
        { label: 'Red B', processStage: 'Red' },
        { label: 'A fijn Light', processStage: 'Light' },
        { label: 'A fijn Middle', processStage: 'Middle' },
        { label: 'Red 5 Pack', processStage: 'Red' },
        { label: 'Middle 5 Pack', processStage: 'Middle' },
        { label: 'Light 5 Pack', processStage: 'Light' },
      ];
};
