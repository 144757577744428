import styled from 'styled-components';

export const FilterSection = styled.div`
  background-color: ${props => props.theme.colors.background.paper};
  border-radius: ${props => props.theme.borderRadius.lg};
  padding: ${props => props.theme.spacing.lg};
  margin-bottom: ${props => props.theme.spacing.lg};
  box-shadow: ${props => props.theme.shadows.sm};
`;

export const FilterGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: ${props => props.theme.spacing.md};

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const FilterSelect = styled.select`
  width: 100%;
  padding: ${props => props.theme.spacing.sm};
  border: 1px solid ${props => props.theme.colors.gray[300]};
  border-radius: ${props => props.theme.borderRadius.md};
  background-color: ${props => props.theme.colors.background.default};
  font-size: ${props => props.theme?.typography?.body2?.fontSize || '0.875rem'};
  color: ${props => props.theme.colors.text.primary};
  transition: ${props => props.theme.transitions.default};

  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.primary.main};
    box-shadow: 0 0 0 3px ${props => props.theme.colors.primary.light}40;
  }
`;

export const FilterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${props => props.theme.spacing.md};
`;

export const FilterTitle = styled.h3`
  font-size: ${props => props.theme.typography.body1.fontSize};
  font-weight: 600;
  color: ${props => props.theme.colors.text.primary};
`;

export const ClearFiltersButton = styled.button`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacing.sm};
  background-color: transparent;
  color: ${props => props.theme.colors.text.secondary};
  border: none;
  cursor: pointer;
  font-size: ${props => props.theme.typography.body2.fontSize};
  transition: ${props => props.theme.transitions.default};

  &:hover {
    color: ${props => props.theme.colors.text.primary};
  }
`;
