import React, { useState, useEffect } from 'react';
import { updateFilterRanges, getFilterRanges } from '@operator/services/operatorService';
import {
  Container,
  Header,
  Form,
  FormGroup,
  Label,
  Input,
  SaveButton,
  Message
} from './styles';

export const Settings = ({ locationId, onSettingsUpdate }) => {
  const [ranges, setRanges] = useState({
    light: { min: 0, max: 0 },
    middle: { min: 0, max: 0 },
    red: { min: 0, max: 0 }
  });
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadSettings();
  }, [locationId]);

  const loadSettings = async () => {
    try {
      const data = await getFilterRanges(locationId);
      setRanges(data);
    } catch (error) {
      console.error('Failed to load settings:', error);
      setMessage('Failed to load settings');
    }
  };

  const handleChange = (filter, field, value) => {
    setRanges(prev => ({
      ...prev,
      [filter]: {
        ...prev[filter],
        [field]: Number(value)
      }
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');

    try {
      await updateFilterRanges(locationId, ranges);
      setMessage('Settings saved successfully');
      if (onSettingsUpdate) {
        onSettingsUpdate();
      }
    } catch (error) {
      console.error('Failed to save settings:', error);
      setMessage('Failed to save settings');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Header>
        <h2>Filter Settings</h2>
      </Header>
      <Form onSubmit={handleSubmit}>
        {Object.entries(ranges).map(([filter, values]) => (
          <div key={filter}>
            <h3>{filter.charAt(0).toUpperCase() + filter.slice(1)}</h3>
            <FormGroup>
              <Label>Min</Label>
              <Input
                type="number"
                value={values.min}
                onChange={(e) => handleChange(filter, 'min', e.target.value)}
                min="0"
                required
              />
            </FormGroup>
            <FormGroup>
              <Label>Max</Label>
              <Input
                type="number"
                value={values.max}
                onChange={(e) => handleChange(filter, 'max', e.target.value)}
                min="0"
                required
              />
            </FormGroup>
          </div>
        ))}
        <SaveButton type="submit" disabled={loading}>
          {loading ? 'Saving...' : 'Save Settings'}
        </SaveButton>
        {message && <Message>{message}</Message>}
      </Form>
    </Container>
  );
};
