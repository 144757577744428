import React, { useState } from 'react';
import { Modal, ModalContent } from '@lib/components/Modal';
import {
  StepContainer,
  ImageGrid,
  ImageButton,
  Image,
  NavigationButtons,
  Button,
  StepTitle,
  ConfirmationInfo,
  InfoRow,
  ImageLabel
} from './styles';
import { 
  palletTypes, 
  boxTypes 
} from '@lib/constants';
import { 
  validatePalletData,
  finishPallet,
  getMaxLayers,
  calculateTotalBoxes
} from '@operator/services/operatorService';

export const AddPalletMobile = ({ 
  onPalletAdded, 
  onCancel,
  position,
  tomatoType,
  processStage,
  tomatoOption,
  isOpen
}) => {
  const [step, setStep] = useState(1);
  const [palletData, setPalletData] = useState({
    palletType: null,
    boxType: null,
    processStage,
    tomatoOption,
    tomatoType
  });

  const handleNext = (key, value) => {
    setPalletData(prev => ({ ...prev, [key]: value }));
    setStep(prev => prev + 1);
  };

  const handleBack = () => {
    setStep(prev => prev - 1);
  };

  const handleSubmit = async () => {
    try {
      // Validate the pallet data
      await validatePalletData({
        ...palletData,
        position
      });

      // Calculate layers and boxes
      const maxLayers = getMaxLayers(palletData.palletType, palletData.boxType);
      const totalBoxes = calculateTotalBoxes(
        palletData.palletType,
        palletData.boxType,
        maxLayers
      );

      // Create the pallet with position data
      const data = {
        ...palletData,
        position,
        layers: maxLayers,
        totalBoxes,
        additionalBoxes: 0,
        addedDate: new Date().toISOString()
      };
      
      onPalletAdded(data);
    } catch (error) {
      console.error('Failed to validate pallet:', error);
      // You might want to show an error message to the user here
    }
  };

  const handleClose = () => {
    setStep(1);
    setPalletData({
      palletType: null,
      boxType: null,
      processStage,
      tomatoOption,
      tomatoType
    });
    onCancel();
  };

  const renderPalletTypeStep = () => (
    <StepContainer>
      <StepTitle>Select Pallet Type</StepTitle>
      <ImageGrid>
        {palletTypes.map(({ type, img }) => (
          <ImageButton 
            key={type} 
            onClick={() => handleNext('palletType', type)}
            selected={palletData.palletType === type}
            type="button"
          >
            <Image src={img} alt={type} />
            <ImageLabel>{type}</ImageLabel>
          </ImageButton>
        ))}
      </ImageGrid>
      <NavigationButtons>
        <Button variant="secondary" onClick={handleClose} type="button">
          Cancel
        </Button>
      </NavigationButtons>
    </StepContainer>
  );

  const renderBoxTypeStep = () => (
    <StepContainer>
      <StepTitle>Select Box Type</StepTitle>
      <ImageGrid>
        {boxTypes.map(({ type, img }) => (
          <ImageButton 
            key={type} 
            onClick={() => handleNext('boxType', type)}
            selected={palletData.boxType === type}
            type="button"
          >
            <Image src={img} alt={type} />
            <ImageLabel>{type}</ImageLabel>
          </ImageButton>
        ))}
      </ImageGrid>
      <NavigationButtons>
        <Button variant="secondary" onClick={handleBack} type="button">
          Back
        </Button>
        <Button variant="secondary" onClick={handleClose} type="button">
          Cancel
        </Button>
      </NavigationButtons>
    </StepContainer>
  );

  const renderConfirmationStep = () => (
    <StepContainer>
      <StepTitle>Confirm Selection</StepTitle>
      <ConfirmationInfo>
        <InfoRow>
          <strong>Position:</strong>
          <span>{position}</span>
        </InfoRow>
        <InfoRow>
          <strong>Pallet Type:</strong>
          <span>{palletData.palletType}</span>
        </InfoRow>
        <InfoRow>
          <strong>Box Type:</strong>
          <span>{palletData.boxType}</span>
        </InfoRow>
        <InfoRow>
          <strong>Process Stage:</strong>
          <span>{processStage}</span>
        </InfoRow>
        <InfoRow>
          <strong>Tomato Type:</strong>
          <span>{tomatoType}</span>
        </InfoRow>
        <InfoRow>
          <strong>Tomato Option:</strong>
          <span>{tomatoOption}</span>
        </InfoRow>
      </ConfirmationInfo>
      <NavigationButtons>
        <Button variant="secondary" onClick={handleBack} type="button">
          Back
        </Button>
        <Button onClick={handleSubmit} type="button">
          Confirm
        </Button>
      </NavigationButtons>
    </StepContainer>
  );

  const getStepContent = () => {
    switch (step) {
      case 1:
        return renderPalletTypeStep();
      case 2:
        return renderBoxTypeStep();
      case 3:
        return renderConfirmationStep();
      default:
        return null;
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalContent
        title={`Add Pallet - Step ${step} of 3`}
        onClose={handleClose}
        size="lg"
      >
        {getStepContent()}
      </ModalContent>
    </Modal>
  );
};
