import styled from 'styled-components';
import { theme } from '@styles/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: ${theme.colors.background.default};
  padding: ${theme.spacing.xl};
`;

export const Button = styled.button`
  background-color: ${theme.colors.primary.main};
  color: ${theme.colors.common.white};
  font-size: ${theme.typography.h2.fontSize};
  padding: ${theme.spacing.md} ${theme.spacing.xl};
  margin: ${theme.spacing.md};
  border: none;
  border-radius: ${theme.borderRadius.md};
  cursor: pointer;
  transition: ${theme.transitions.default};
  width: 80%;
  max-width: 300px;
  font-weight: 600;
  box-shadow: ${theme.shadows.md};

  &:hover {
    background-color: ${theme.colors.primary.dark};
    box-shadow: ${theme.shadows.lg};
  }

  &:active {
    transform: translateY(1px);
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

export const Input = styled.input`
  padding: ${theme.spacing.md};
  margin: ${theme.spacing.md};
  font-size: ${theme.typography.body1.fontSize};
  border: 2px solid ${theme.colors.border.light};
  border-radius: ${theme.borderRadius.md};
  width: 80%;
  max-width: 300px;
  transition: ${theme.transitions.default};

  &:focus {
    outline: none;
    border-color: ${theme.colors.primary.main};
    box-shadow: 0 0 0 2px ${theme.colors.primary.lighter};
  }

  &::placeholder {
    color: ${theme.colors.text.disabled};
  }
`;

export const Title = styled.h1`
  color: ${theme.colors.text.primary};
  font-size: ${theme.typography.h1.fontSize};
  font-weight: ${theme.typography.h1.fontWeight};
  margin-bottom: ${theme.spacing.xl};
  text-align: center;
`;

export const ErrorMessage = styled.div`
  color: ${theme.colors.error};
  font-size: ${theme.typography.body2.fontSize};
  margin-bottom: ${theme.spacing.md};
  text-align: center;
  max-width: 300px;
  padding: ${theme.spacing.sm};
  border-radius: ${theme.borderRadius.sm};
  background-color: ${`${theme.colors.error}10`};
`;
