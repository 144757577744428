import React from 'react';
import { Container, PlaceholderMessage } from './styles';

export const KleinpakConsole = ({ locationId, userId }) => {
  return (
    <Container>
      <PlaceholderMessage>
        Kleinpak functionality coming soon...
      </PlaceholderMessage>
    </Container>
  );
};
