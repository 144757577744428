import React from 'react';
import { Plus } from 'lucide-react';
import OrderCard from './OrderCard';
import styled from 'styled-components';

const Container = styled.div`
  padding: ${({ theme }) => theme.spacing.lg};
  background-color: ${({ theme }) => theme.colors.background.paper};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  box-shadow: ${({ theme }) => theme.shadows.md};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.lg};
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.h3`
  ${({ theme }) => theme.typography.h3};
  color: ${({ theme }) => theme.colors.text.primary};
  margin-right: ${({ theme }) => theme.spacing.lg};
`;

const FilterButton = styled.button`
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.md}`};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  background-color: ${({ active, theme }) => 
    active ? theme.colors.primary.main : theme.colors.background.paper};
  color: ${({ active, theme }) => 
    active ? theme.colors.common.white : theme.colors.text.primary};
  ${({ theme }) => theme.typography.body2};
  transition: ${({ theme }) => theme.transitions.default};

  &:hover {
    background-color: ${({ active, theme }) => 
      active ? theme.colors.primary.dark : theme.colors.background.hover};
  }
`;

const AddButton = styled.button`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.md}`};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  background-color: ${({ theme }) => theme.colors.primary.main};
  color: ${({ theme }) => theme.colors.common.white};
  ${({ theme }) => theme.typography.body2};
  transition: ${({ theme }) => theme.transitions.default};

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.dark};
  }

  svg {
    width: ${({ theme }) => theme.spacing.lg};
    height: ${({ theme }) => theme.spacing.lg};
  }
`;

const OrderList = ({
  orders,
  showCompletedOrders,
  onToggleCompleted,
  onAddOrder,
  onUpdatePriority,
  onShipOrder,
  onEditOrder,
  onDeleteOrder
}) => {
  return (
    <Container>
      <Header>
        <HeaderLeft>
          <Title>Orders</Title>
          <FilterButton
            active={showCompletedOrders}
            onClick={onToggleCompleted}
          >
            {showCompletedOrders ? 'Hide Completed' : 'Show Completed'}
          </FilterButton>
        </HeaderLeft>
        <AddButton onClick={onAddOrder}>
          <Plus />
          Add Order
        </AddButton>
      </Header>

      {orders.map(order => (
        <OrderCard
          key={order.id}
          order={order}
          onUpdatePriority={onUpdatePriority}
          onShip={onShipOrder}
          onEdit={onEditOrder}
          onDelete={onDeleteOrder}
        />
      ))}
    </Container>
  );
};

export default OrderList;
