import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { X, AlertCircle, CheckCircle, Info, AlertTriangle } from 'lucide-react';

const slideIn = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const ToastOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  z-index: ${({ theme }) => theme.zIndex.toast};
`;

const ToastContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.background.paper};
  color: ${({ theme, variant }) => {
    switch (variant) {
      case 'error':
        return theme.colors.error.main;
      case 'success':
        return theme.colors.success.main;
      case 'warning':
        return theme.colors.warning.main;
      default:
        return theme.colors.info.main;
    }
  }};
  padding: ${({ theme }) => theme.spacing.lg};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  box-shadow: ${({ theme }) => theme.shadows.lg};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.md};
  min-width: 300px;
  max-width: 500px;
  pointer-events: all;
  animation: ${slideIn} 0.3s ease-out,
    ${fadeOut} 0.3s ease-in forwards;
  animation-delay: 0s, ${({ duration }) => (duration - 300)}ms;
`;

const IconWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  flex-grow: 1;
  ${({ theme }) => theme.typography.body1};
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing.xs};
  color: currentColor;
  opacity: 0.7;
  transition: ${({ theme }) => theme.transitions.fast};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  
  &:hover {
    opacity: 1;
    background-color: ${({ theme }) => theme.colors.background.hover};
  }
`;

const getIcon = (variant) => {
  switch (variant) {
    case 'error':
      return AlertCircle;
    case 'success':
      return CheckCircle;
    case 'warning':
      return AlertTriangle;
    default:
      return Info;
  }
};

const Toast = ({ message, variant = 'info', duration = 3000, onClose }) => {
  const Icon = getIcon(variant);

  useEffect(() => {
    if (duration !== Infinity) {
      const timer = setTimeout(onClose, duration);
      return () => clearTimeout(timer);
    }
  }, [duration, onClose]);

  return (
    <ToastOverlay>
      <ToastContainer variant={variant} duration={duration}>
        <IconWrapper>
          <Icon size={24} />
        </IconWrapper>
        <Content>{message}</Content>
        <CloseButton onClick={onClose}>
          <X size={20} />
        </CloseButton>
      </ToastContainer>
    </ToastOverlay>
  );
};

export default Toast;
