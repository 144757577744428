export const weightRangeOptions = {
  light: {
    min: 0,
    max: 100,
    label: 'Light Weight',
    value: '0-100'
  },
  medium: {
    min: 101,
    max: 200,
    label: 'Medium Weight',
    value: '101-200'
  },
  heavy: {
    min: 201,
    max: 300,
    label: 'Heavy Weight',
    value: '201-300'
  },
  custom: {
    label: 'Custom Range',
    value: 'custom'
  }
};
