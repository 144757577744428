import styled from 'styled-components';

export const ActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${props => props.theme.spacing.sm};
  border: none;
  border-radius: ${props => props.theme.borderRadius.md};
  background-color: ${props => {
    switch (props.variant) {
      case 'ship':
        return props.theme.colors.success + '20';
      case 'edit':
        return props.theme.colors.warning + '20';
      case 'delete':
        return props.theme.colors.error + '20';
      case 'return':
        return props.theme.colors.info + '20';
      case 'split':
        return props.theme.colors.secondary.main + '20';
      default:
        return props.theme.colors.gray[200];
    }
  }};
  color: ${props => {
    switch (props.variant) {
      case 'ship':
        return props.theme.colors.success;
      case 'edit':
        return props.theme.colors.warning;
      case 'delete':
        return props.theme.colors.error;
      case 'return':
        return props.theme.colors.info;
      case 'split':
        return props.theme.colors.secondary.main;
      default:
        return props.theme.colors.text.primary;
    }
  }};
  cursor: pointer;
  transition: ${props => props.theme.transitions.default};
  width: 32px;
  height: 32px;

  &:hover {
    background-color: ${props => {
      switch (props.variant) {
        case 'ship':
          return props.theme.colors.success + '40';
        case 'edit':
          return props.theme.colors.warning + '40';
        case 'delete':
          return props.theme.colors.error + '40';
        case 'return':
          return props.theme.colors.info + '40';
        case 'split':
          return props.theme.colors.secondary.main + '40';
        default:
          return props.theme.colors.gray[300];
      }
    }};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${props => {
      switch (props.variant) {
        case 'ship':
          return props.theme.colors.success + '40';
        case 'edit':
          return props.theme.colors.warning + '40';
        case 'delete':
          return props.theme.colors.error + '40';
        case 'return':
          return props.theme.colors.info + '40';
        case 'split':
          return props.theme.colors.secondary.main + '40';
        default:
          return props.theme.colors.gray[300];
      }
    }};
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const Badge = styled.span`
  display: inline-flex;
  align-items: center;
  padding: ${props => props.theme.spacing.xs} ${props => props.theme.spacing.sm};
  border-radius: ${props => props.theme.borderRadius.sm};
  font-size: ${props => props.theme.typography.body2.fontSize};
  font-weight: 500;
  white-space: nowrap;
  background-color: ${props => {
    switch (props.status) {
      case 'completed':
      case 'success':
      case 'processed':
      case 'ready_for_shipping':
        return props.theme.colors.success + '20';
      case 'warning':
      case 'unprocessed':
      case 'quality_check':
        return props.theme.colors.warning + '20';
      case 'error':
      case 'returned':
        return props.theme.colors.error + '20';
      case 'info':
      case 'in_processing':
        return props.theme.colors.info + '20';
      case 'shipped':
        return props.theme.colors.primary.main + '20';
      case 'assigned':
        return props.theme.colors.secondary.main + '20';
      default:
        return props.theme.colors.gray[200];
    }
  }};
  color: ${props => {
    switch (props.status) {
      case 'completed':
      case 'success':
      case 'processed':
      case 'ready_for_shipping':
        return props.theme.colors.success;
      case 'warning':
      case 'unprocessed':
      case 'quality_check':
        return props.theme.colors.warning;
      case 'error':
      case 'returned':
        return props.theme.colors.error;
      case 'info':
      case 'in_processing':
        return props.theme.colors.info;
      case 'shipped':
        return props.theme.colors.primary.main;
      case 'assigned':
        return props.theme.colors.secondary.main;
      default:
        return props.theme.colors.text.secondary;
    }
  }};
`;

export const ResultCount = styled.div`
  font-size: ${props => props.theme.typography.body2.fontSize};
  color: ${props => props.theme.colors.text.secondary};
  margin-bottom: ${props => props.theme.spacing.md};
`;
