import React, { useEffect, useRef, useCallback } from 'react';
import styled, { keyframes } from 'styled-components';
import Portal from '@lib/utils/portal';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const scaleIn = keyframes`
  from {
    transform: scale(0.95);
  }
  to {
    transform: scale(1);
  }
`;

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${({ theme }) => theme.zIndex.modal};
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-out, visibility 0.2s ease-out;

  &[data-open="true"] {
    opacity: 1;
    visibility: visible;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  animation: ${fadeIn} 0.2s ease-out;
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: ${({ theme }) => theme.zIndex.modal + 1};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 90vh;
  padding: ${({ theme }) => theme.spacing.xl};
  margin: ${({ theme }) => theme.spacing.xl};

  & > * {
    animation: ${scaleIn} 0.2s ease-out;
    max-height: calc(90vh - ${({ theme }) => theme.spacing.xl} * 2);
    overflow-y: auto;
  }
`;

const Modal = ({ children, onClose, isOpen }) => {
  const containerRef = useRef(null);
  const previousBodyOverflow = useRef(null);

  // Lock body scroll when modal is open
  useEffect(() => {
    if (isOpen) {
      previousBodyOverflow.current = document.body.style.overflow;
      document.body.style.overflow = 'hidden';
    } else if (previousBodyOverflow.current !== null) {
      document.body.style.overflow = previousBodyOverflow.current;
    }
    
    return () => {
      if (previousBodyOverflow.current !== null) {
        document.body.style.overflow = previousBodyOverflow.current;
      }
    };
  }, [isOpen]);

  // Handle escape key press
  const handleEscape = useCallback((e) => {
    if (e.key === 'Escape' && isOpen) {
      onClose?.();
    }
  }, [isOpen, onClose]);

  useEffect(() => {
    if (isOpen) {
      window.addEventListener('keydown', handleEscape);
      return () => window.removeEventListener('keydown', handleEscape);
    }
  }, [isOpen, handleEscape]);

  const handleOverlayClick = useCallback((e) => {
    if (e.target === e.currentTarget) {
      onClose?.();
    }
  }, [onClose]);

  if (!isOpen) return null;

  const modalContent = (
    <ModalWrapper data-open={isOpen}>
      <ModalOverlay onClick={handleOverlayClick} />
      <ModalContainer ref={containerRef}>
        {children}
      </ModalContainer>
    </ModalWrapper>
  );

  return (
    <Portal id="modal-root">
      {modalContent}
    </Portal>
  );
};

export default Modal;
