import React from 'react';
import { Truck, Trash2, Edit } from 'lucide-react';
import { styled } from 'styled-components';

const ToolbarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem 1rem;
  background-color: #f8f9fa;
  border-radius: 0.5rem;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  opacity: ${props => props.$isVisible ? 1 : 0};
  transition: opacity 0.2s ease-in-out;
  pointer-events: ${props => props.$isVisible ? 'auto' : 'none'};
  
  /* Fixed positioning */
  position: fixed;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  
  /* Compact width that fits content */
  width: auto;
  min-width: min-content;
  white-space: nowrap;
  
  /* Add a backdrop blur effect */
  backdrop-filter: blur(8px);
  background-color: rgba(248, 249, 250, 0.95);
`;

const ActionButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: white;
  background-color: ${props => props.$variant === 'delete' ? '#dc2626' : '#2563eb'};
  &:hover {
    background-color: ${props => props.$variant === 'delete' ? '#b91c1c' : '#1d4ed8'};
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.div`
  color: #dc2626;
  font-size: 0.875rem;
`;

const SelectedCount = styled.div`
  font-size: 0.875rem;
  color: #4b5563;
`;

export const BatchActionsToolbar = ({
  selectedCount,
  onShip,
  onDelete,
  onUpdate,
  onAssign,
  isLoading,
  error
}) => {
  const isVisible = selectedCount > 0;

  return (
    <ToolbarContainer $isVisible={isVisible}>
      <SelectedCount>
        {selectedCount} {selectedCount === 1 ? 'pallet' : 'pallets'} selected
      </SelectedCount>
      
      <ActionButton
        onClick={onShip}
        disabled={isLoading}
      >
        <Truck size={16} />
        Ship
      </ActionButton>

      <ActionButton
        onClick={onAssign}
        disabled={isLoading}
      >
        <Edit size={16} />
        Assign to Order
      </ActionButton>

      <ActionButton
        onClick={onUpdate}
        disabled={isLoading}
      >
        <Edit size={16} />
        Update
      </ActionButton>

      <ActionButton
        onClick={onDelete}
        disabled={isLoading}
        $variant="delete"
      >
        <Trash2 size={16} />
        Delete
      </ActionButton>

      {error && <ErrorMessage>{error}</ErrorMessage>}
    </ToolbarContainer>
  );
};

export default BatchActionsToolbar;
