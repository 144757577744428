import React from 'react';
import { ExtraBoxesInput } from '../styles';

const ExtraBoxesForm = ({
  value,
  onChange
}) => {
  return (
    <ExtraBoxesInput>
      <label htmlFor="extraBoxes">
        Extra boxes used for processing (optional):
      </label>
      <input
        id="extraBoxes"
        type="number"
        min="0"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder="Leave empty if still processing"
      />
    </ExtraBoxesInput>
  );
};

export default ExtraBoxesForm;
