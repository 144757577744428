import { useState, useCallback, useMemo } from 'react';

export const useDashboardFilters = ({ pallets = [], orders = [], filters = {}, isLoading = true }) => {
  const [selectedPallets, setSelectedPallets] = useState(new Set());
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

  const filterItems = useCallback((items, itemType) => {
    if (isLoading || !Array.isArray(items)) return [];
    
    let filtered = [...items];

    // Common filters
    if (filters?.dateRange) {
      const today = new Date();
      const startDate = new Date(today);
      
      switch (filters.dateRange) {
        case 'today':
          startDate.setHours(0, 0, 0, 0);
          break;
        case 'week':
          startDate.setDate(today.getDate() - 7);
          break;
        case 'month':
          startDate.setMonth(today.getMonth() - 1);
          break;
        default:
          break;
      }

      filtered = filtered.filter(item => {
        if (!item?.addedDate) return false;
        const itemDate = new Date(item.addedDate);
        return itemDate >= startDate && itemDate <= today;
      });
    }

    if (filters?.tomatoType) {
      filtered = filtered.filter(item => 
        item?.tomatoType === filters.tomatoType
      );
    }

    if (filters?.boxType) {
      filtered = filtered.filter(item => 
        item?.boxType === filters.boxType
      );
    }

    // Pallet-specific filters
    if (itemType === 'pallets') {
      if (filters?.palletStatus) {
        filtered = filtered.filter(pallet => 
          pallet?.status === filters.palletStatus
        );
      }

      if (filters?.weightRange && filters.weightRange !== 'custom') {
        const [min, max] = filters.weightRange.split('-').map(Number);
        filtered = filtered.filter(pallet => {
          const weight = Number(pallet?.weight || 0);
          return weight >= min && weight <= max;
        });
      }
    }

    // Order-specific filters
    if (itemType === 'orders') {
      if (filters?.orderStatus) {
        filtered = filtered.filter(order => 
          order?.status === filters.orderStatus
        );
      }

      if (filters?.dueDate) {
        const dueDate = new Date(filters.dueDate);
        dueDate.setHours(0, 0, 0, 0);
        
        filtered = filtered.filter(order => {
          if (!order?.due_date) return false;
          const orderDueDate = new Date(order.due_date);
          orderDueDate.setHours(0, 0, 0, 0);
          return orderDueDate.getTime() === dueDate.getTime();
        });
      }

      if (filters?.dueDateRange) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        
        filtered = filtered.filter(order => {
          if (!order?.due_date) return false;
          const dueDate = new Date(order.due_date);
          dueDate.setHours(0, 0, 0, 0);
          
          switch (filters.dueDateRange) {
            case 'overdue':
              return dueDate < today;
            case 'today':
              return dueDate.getTime() === today.getTime();
            case 'tomorrow':
              const tomorrow = new Date(today);
              tomorrow.setDate(today.getDate() + 1);
              return dueDate.getTime() === tomorrow.getTime();
            case 'thisWeek': {
              const endOfWeek = new Date(today);
              endOfWeek.setDate(today.getDate() + (7 - today.getDay()));
              return dueDate >= today && dueDate <= endOfWeek;
            }
            default:
              return true;
          }
        });
      }
    }

    return filtered;
  }, [filters, isLoading]);

  const sortItems = useCallback((itemsToSort) => {
    if (!Array.isArray(itemsToSort) || !sortConfig.key) return itemsToSort;

    return [...itemsToSort].sort((a, b) => {
      if (!a || !b) return 0;
      
      let aValue = a[sortConfig.key];
      let bValue = b[sortConfig.key];

      // Handle special sorting cases
      if (sortConfig.key === 'due_date') {
        aValue = aValue ? new Date(aValue).getTime() : Infinity;
        bValue = bValue ? new Date(bValue).getTime() : Infinity;
      } else if (sortConfig.key === 'weight' || sortConfig.key === 'colli_completion') {
        aValue = Number(aValue || 0);
        bValue = Number(bValue || 0);
      }

      if (aValue === undefined || bValue === undefined) return 0;

      if (typeof aValue === 'string') {
        const comparison = aValue.localeCompare(bValue);
        return sortConfig.direction === 'asc' ? comparison : -comparison;
      }

      if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    });
  }, [sortConfig]);

  const handleSelectPallet = useCallback((palletId) => {
    if (isLoading) return;
    
    setSelectedPallets(prev => {
      const newSet = new Set(prev);
      if (newSet.has(palletId)) {
        newSet.delete(palletId);
      } else {
        newSet.add(palletId);
      }
      return newSet;
    });
  }, [isLoading]);

  const handleSelectAll = useCallback((checked) => {
    if (isLoading || !Array.isArray(pallets)) {
      setSelectedPallets(new Set());
      return;
    }

    if (checked) {
      const filteredPallets = filterItems(pallets, 'pallets');
      const allIds = filteredPallets
        .filter(pallet => pallet && pallet.id)
        .map(pallet => pallet.id);
      setSelectedPallets(new Set(allIds));
    } else {
      setSelectedPallets(new Set());
    }
  }, [pallets, filterItems, isLoading]);

  const resetSelection = useCallback(() => {
    setSelectedPallets(new Set());
  }, []);

  const requestSort = useCallback((key) => {
    if (isLoading) return;
    
    setSortConfig(prevConfig => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
    }));
  }, [isLoading]);

  const filteredAndSortedPallets = useMemo(() => {
    if (isLoading) return [];
    
    const filteredPallets = filterItems(pallets, 'pallets');
    return sortItems(filteredPallets);
  }, [pallets, filterItems, sortItems, isLoading]);

  const filteredAndSortedOrders = useMemo(() => {
    if (isLoading) return [];
    
    const filteredOrders = filterItems(orders, 'orders');
    return sortItems(filteredOrders);
  }, [orders, filterItems, sortItems, isLoading]);

  const selectAllChecked = useMemo(() => {
    if (isLoading || !Array.isArray(filteredAndSortedPallets) || filteredAndSortedPallets.length === 0) {
      return false;
    }
    return filteredAndSortedPallets.length > 0 && 
           selectedPallets.size === filteredAndSortedPallets.length;
  }, [filteredAndSortedPallets, selectedPallets, isLoading]);

  return {
    filteredAndSortedPallets,
    filteredAndSortedOrders,
    handleSelectPallet,
    handleSelectAll,
    requestSort,
    selectedPallets,
    selectAllChecked,
    resetSelection,
    sortConfig,
    isLoading
  };
};
