import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

// Get or create a portal container
const usePortal = (id) => {
  const containerRef = useRef(null);

  if (!containerRef.current) {
    // Get existing container
    const existingContainer = document.getElementById(id);
    if (existingContainer) {
      containerRef.current = existingContainer;
    } else {
      // Create new container if needed
      const container = document.createElement('div');
      container.setAttribute('id', id);
      document.body.appendChild(container);
      containerRef.current = container;
    }
  }

  // Cleanup only if we created the container
  useEffect(() => {
    const container = containerRef.current;
    const wasCreatedByUs = !document.getElementById(id);

    return () => {
      if (wasCreatedByUs && container && container.parentElement) {
        container.parentElement.removeChild(container);
      }
    };
  }, [id]);

  return containerRef.current;
};

// Portal component that uses the hook
const Portal = ({ id, children }) => {
  const container = usePortal(id);
  return container ? createPortal(children, container) : null;
};

export default Portal;
