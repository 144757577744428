import React, { useState, useEffect } from 'react';
import { getStatistics } from '@operator/services/operatorService';
import {
  Container,
  Header,
  StatsGrid,
  StatCard,
  StatValue,
  StatLabel,
  LoadingMessage,
  ErrorMessage
} from './styles';

export const Statistics = ({ locationId }) => {
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    loadStatistics();
  }, [locationId]);

  const loadStatistics = async () => {
    try {
      setLoading(true);
      setError(null);
      const data = await getStatistics(locationId);
      setStats(data);
    } catch (error) {
      console.error('Failed to load statistics:', error);
      setError('Failed to load statistics');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <LoadingMessage>Loading statistics...</LoadingMessage>;
  }

  if (error) {
    return <ErrorMessage>{error}</ErrorMessage>;
  }

  if (!stats) {
    return <ErrorMessage>No statistics available</ErrorMessage>;
  }

  return (
    <Container>
      <Header>
        <h2>Statistics</h2>
      </Header>
      <StatsGrid>
        <StatCard>
          <StatValue>{stats.totalPallets}</StatValue>
          <StatLabel>Total Pallets</StatLabel>
        </StatCard>
        <StatCard>
          <StatValue>{stats.averageWeight}kg</StatValue>
          <StatLabel>Average Weight</StatLabel>
        </StatCard>
        <StatCard>
          <StatValue>{stats.completionRate}%</StatValue>
          <StatLabel>Completion Rate</StatLabel>
        </StatCard>
        <StatCard>
          <StatValue>{stats.efficiency}%</StatValue>
          <StatLabel>Efficiency</StatLabel>
        </StatCard>
      </StatsGrid>
    </Container>
  );
};
