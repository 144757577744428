import styled from 'styled-components';
import { theme } from '@styles/theme';

export const OrderList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.md};
  margin-bottom: ${theme.spacing.xl};
`;

export const OrderCard = styled.div`
  padding: ${theme.spacing.lg};
  border: 1px solid ${theme.colors.border.light};
  border-radius: ${theme.borderRadius.md};
  cursor: pointer;
  transition: ${theme.transitions.fast};
  background-color: ${props => 
    props.selected ? theme.colors.primary.lighter : theme.colors.background.paper};
  
  &:hover {
    border-color: ${theme.colors.primary.main};
    background-color: ${props => 
      props.selected ? theme.colors.primary.lighter : theme.colors.background.hover};
  }
`;

export const OrderInfo = styled.div`
  h4 {
    ${theme.typography.h6};
    color: ${theme.colors.text.primary};
    margin-bottom: ${theme.spacing.xs};
  }

  p {
    color: ${theme.colors.text.secondary};
    ${theme.typography.body2};
  }
`;

export const ExtraBoxesInput = styled.div`
  margin-top: ${theme.spacing.xl};

  label {
    display: block;
    margin-bottom: ${theme.spacing.sm};
    color: ${theme.colors.text.primary};
    ${theme.typography.body1};
  }

  input {
    width: 100%;
    padding: ${theme.spacing.md};
    border: 1px solid ${theme.colors.border.main};
    border-radius: ${theme.borderRadius.md};
    ${theme.typography.body1};
    
    &:focus {
      outline: none;
      border-color: ${theme.colors.primary.main};
      box-shadow: 0 0 0 2px ${theme.colors.primary.lighter};
    }
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${theme.spacing.md};
  margin-top: ${theme.spacing.xl};
`;

const Button = styled.button`
  padding: ${theme.spacing.sm} ${theme.spacing.lg};
  border-radius: ${theme.borderRadius.md};
  ${theme.typography.button};
  transition: ${theme.transitions.fast};
  
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const CancelButton = styled(Button)`
  background: none;
  border: 1px solid ${theme.colors.border.main};
  color: ${theme.colors.text.primary};
  
  &:hover:not(:disabled) {
    background: ${theme.colors.background.hover};
  }
`;

export const AssignButton = styled(Button)`
  background: ${theme.colors.primary.main};
  color: ${theme.colors.common.white};
  border: none;
  
  &:hover:not(:disabled) {
    background: ${theme.colors.primary.dark};
  }
`;

export const NoOrdersMessage = styled.div`
  text-align: center;
  padding: ${theme.spacing.xl};
  color: ${theme.colors.text.secondary};
  ${theme.typography.body1};
`;
