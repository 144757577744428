import React from 'react';
import {
  OrderList,
  OrderCard,
  OrderInfo,
  NoOrdersMessage
} from '../styles';

const OrderSelection = ({
  orders,
  selectedOrder,
  onSelectOrder
}) => {
  const activeOrders = orders.filter(order => !order.is_completed);

  if (activeOrders.length === 0) {
    return (
      <NoOrdersMessage>
        No active orders available for assignment.
        <br />
        Create a new order first.
      </NoOrdersMessage>
    );
  }

  return (
    <OrderList>
      {activeOrders.map(order => (
        <OrderCard
          key={order.id}
          selected={selectedOrder?.id === order.id}
          onClick={() => onSelectOrder(order)}
        >
          <OrderInfo>
            <h4>{order.customer_name}</h4>
            <p>Type: {order.tomatoType} - {order.tomatoOption}</p>
            <p>Box: {order.boxType}</p>
            <p>Progress: {order.assigned_pallets || 0} / {order.total_pallets} pallets</p>
          </OrderInfo>
        </OrderCard>
      ))}
    </OrderList>
  );
};

export default OrderSelection;
