import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  background-color: ${({ theme }) => theme.colors.background.paper};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  box-shadow: ${({ theme }) => theme.shadows.sm};

  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.background.default};
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.gray[400]};
    border-radius: 4px;

    &:hover {
      background: ${({ theme }) => theme.colors.gray[500]};
    }
  }
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin: 0;
  padding: 0;

  thead {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: ${({ theme }) => theme.colors.background.default};
  }

  tbody {
    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }

  ${({ compact, theme }) => compact && `
    th, td {
      padding: ${theme.spacing.sm} ${theme.spacing.md};
    }
  `}

  ${({ hover, theme }) => hover && `
    tbody tr:hover {
      background-color: ${theme.colors.background.hover};
    }
  `}

  ${({ striped, theme }) => striped && `
    tbody tr:nth-child(even) {
      background-color: ${theme.colors.background.default};
    }
  `}

  ${({ bordered, theme }) => bordered && `
    th, td {
      border: 1px solid ${theme.colors.border.light};
    }
  `}
`;

const Table = ({ 
  children, 
  className,
  compact,
  hover = true,
  striped,
  bordered,
  style 
}) => {
  return (
    <TableContainer className={className} style={style}>
      <StyledTable
        compact={compact}
        hover={hover}
        striped={striped}
        bordered={bordered}
      >
        {children}
      </StyledTable>
    </TableContainer>
  );
};

Table.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  compact: PropTypes.bool,
  hover: PropTypes.bool,
  striped: PropTypes.bool,
  bordered: PropTypes.bool,
  style: PropTypes.object
};

Table.defaultProps = {
  compact: false,
  hover: true,
  striped: false,
  bordered: false
};

export default Table;
