// src/GlobalStyle.js
import { createGlobalStyle } from 'styled-components';
import { theme } from './theme';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    margin: 0;
    font-family: ${theme.typography.fontFamily};
    background-color: ${theme.colors.background.default};
    color: ${theme.colors.text.primary};
    line-height: ${theme.typography.body1.lineHeight};
    font-size: ${theme.typography.body1.fontSize};
  }

  a {
    color: ${theme.colors.primary.main};
    text-decoration: none;
    transition: ${theme.transitions.default};

    &:hover {
      color: ${theme.colors.primary.dark};
    }
  }

  button {
    font-family: ${theme.typography.fontFamily};
  }

  input, select, textarea {
    font-family: ${theme.typography.fontFamily};
  }
`;

export default GlobalStyle;
