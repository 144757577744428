export const theme = {
  colors: {
    // Common colors
    common: {
      white: '#FFFFFF',
      black: '#000000',
      transparent: 'transparent'
    },
    // Primary palette - Tomato-inspired reds
    primary: {
      lighter: '#FFCDD2',
      light: '#FF6B6B',
      main: '#E53935',  // Tomato red
      dark: '#C62828',
      darker: '#B71C1C',
    },
    // Secondary palette - Fresh greens for contrast
    secondary: {
      light: '#66BB6A',
      main: '#43A047',  // Fresh leaf green
      dark: '#2E7D32',
    },
    // Status colors
    success: '#2E7D32',  // Deeper green for better contrast
    warning: '#F57C00',  // Warmer orange
    error: '#D32F2F',    // Consistent red
    info: '#1976D2',     // Clearer blue
    // Neutral grays - Warmer undertones
    gray: {
      100: '#F8F9FA',
      200: '#F1F3F5',
      300: '#E9ECEF',
      400: '#DEE2E6',
      500: '#ADB5BD',
      600: '#868E96',
      700: '#495057',
      800: '#343A40',
      900: '#212529',
    },
    // Background variations - Subtle warmth
    background: {
      default: '#FAFAFA',
      paper: '#FFFFFF',
      card: '#FFFFFF',
      hover: '#F5F5F5',
      accent: '#FFF5F5',  // Very light tomato tint
      darker: '#E0E0E0',
      darkest: '#BDBDBD',
    },
    // Text colors - Improved contrast
    text: {
      primary: '#212529',
      secondary: '#495057',
      disabled: '#ADB5BD',
      accent: '#E53935',  // Tomato red for emphasis
      inverse: '#FFFFFF',
    },
    // Border colors
    border: {
      light: '#E0E0E0',
      main: '#BDBDBD',
      dark: '#9E9E9E',
    },
  },
  typography: {
    fontFamily: "'Inter', 'Roboto', 'Helvetica Neue', sans-serif",
    h1: {
      fontSize: '2rem',
      fontWeight: 600,
      lineHeight: 1.2,
      letterSpacing: '-0.01562em',
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 600,
      lineHeight: 1.3,
      letterSpacing: '-0.00833em',
    },
    h3: {
      fontSize: '1.25rem',
      fontWeight: 600,
      lineHeight: 1.4,
      letterSpacing: '0em',
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: '0.00938em',
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
    },
    caption: {
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: 1.66,
      letterSpacing: '0.03333em',
    },
  },
  spacing: {
    xs: '0.25rem',    // 4px
    sm: '0.5rem',     // 8px
    md: '1rem',       // 16px
    lg: '1.5rem',     // 24px
    xl: '2rem',       // 32px
    xxl: '3rem',      // 48px
  },
  shadows: {
    sm: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
    md: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
    xl: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
  },
  transitions: {
    default: 'all 0.2s ease-in-out',
    fast: 'all 0.1s ease-in-out',
    slow: 'all 0.3s ease-in-out',
  },
  borderRadius: {
    sm: '0.125rem',   // 2px
    md: '0.25rem',    // 4px
    lg: '0.5rem',     // 8px
    xl: '1rem',       // 16px
    full: '9999px',
  },
  zIndex: {
    toast: 1300,
    loading: 1200,
    modal: 1100,
    popover: 1000,
    tooltip: 900,
    header: 800,
    drawer: 700,
  },
};
