import { useState, useEffect, useCallback } from 'react';
import { api } from '@lib/api';

export const useLocationManagement = (user) => {
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const loadUserLocations = useCallback(async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setError('No authentication token found');
      setIsLoading(false);
      return [];
    }

    if (!user?.id) {
      setError('User information not available');
      setIsLoading(false);
      return [];
    }

    try {
      setIsLoading(true);
      setError(null);

      const response = await api.get(`/api/user-locations/user/${user.id}`);
      
      if (!response.data.success) {
        throw new Error(response.data.message || 'Failed to load locations');
      }

      const locationData = response.data.data || [];
      
      // Sort locations alphabetically
      const sortedLocations = locationData.sort((a, b) => 
        a.name.localeCompare(b.name)
      );

      setLocations(sortedLocations);

      // If there's a previously selected location in localStorage, use it
      const savedLocationId = localStorage.getItem('selectedLocationId');
      if (savedLocationId) {
        const savedLocation = sortedLocations.find(
          loc => loc.id === parseInt(savedLocationId)
        );
        if (savedLocation) {
          setSelectedLocation(savedLocation);
          return sortedLocations;
        }
      }

      // Otherwise, set the first location as selected
      if (sortedLocations.length > 0) {
        setSelectedLocation(sortedLocations[0]);
      }

      return sortedLocations;
    } catch (error) {
      setError(error.message || 'Failed to load locations');
      return [];
    } finally {
      setIsLoading(false);
    }
  }, [user]);

  const handleLocationChange = useCallback((locationId) => {
    const location = locations.find(loc => loc.id === parseInt(locationId));
    if (location) {
      setSelectedLocation(location);
      localStorage.setItem('selectedLocationId', location.id.toString());
    }
  }, [locations]);

  // Load locations when user changes
  useEffect(() => {
    if (user) {
      loadUserLocations();
    }
  }, [user, loadUserLocations]);

  // Validate selected location whenever locations change
  useEffect(() => {
    if (locations.length > 0 && selectedLocation) {
      // Check if the selected location still exists in the updated locations list
      const locationStillExists = locations.some(loc => loc.id === selectedLocation.id);
      if (!locationStillExists) {
        // If not, select the first available location
        setSelectedLocation(locations[0]);
        localStorage.setItem('selectedLocationId', locations[0].id.toString());
      }
    }
  }, [locations, selectedLocation]);

  return {
    locations,
    selectedLocation,
    setSelectedLocation: handleLocationChange,
    loadUserLocations,
    error,
    isLoading
  };
};
