import React from 'react';
import { Calendar, Box, Package, Palette, AlertCircle, Truck, Edit, X } from 'lucide-react';
import styled from 'styled-components';

const Card = styled.div`
  padding: ${({ theme }) => theme.spacing.lg};
  background-color: ${({ theme }) => theme.colors.background.paper};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  border: 1px solid ${({ theme }) => theme.colors.border.light};
  margin-bottom: ${({ theme }) => theme.spacing.md};
  transition: ${({ theme }) => theme.transitions.default};

  &:hover {
    border-color: ${({ theme }) => theme.colors.primary.light};
    box-shadow: ${({ theme }) => theme.shadows.sm};
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spacing.lg};

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.md};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.md};

  h4 {
    ${({ theme }) => theme.typography.h3};
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.sm};

  p {
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.sm};
    ${({ theme }) => theme.typography.body2};
    color: ${({ theme }) => theme.colors.text.secondary};

    svg {
      color: ${({ theme }) => theme.colors.text.secondary};
    }
  }
`;

const ProgressSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.lg};
`;

const Progress = styled.div`
  background-color: ${({ theme }) => theme.colors.background.darker};
  height: 8px;
  border-radius: ${({ theme }) => theme.borderRadius.full};
  overflow: hidden;

  div {
    height: 100%;
    background-color: ${({ theme }) => theme.colors.primary.main};
    transition: width 0.3s ease;
  }
`;

const Metrics = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: ${({ theme }) => theme.spacing.md};

  div {
    p:first-child {
      ${({ theme }) => theme.typography.caption};
      color: ${({ theme }) => theme.colors.text.secondary};
      margin-bottom: ${({ theme }) => theme.spacing.xs};
    }

    p:last-child {
      ${({ theme }) => theme.typography.body1};
      color: ${({ theme }) => theme.colors.text.primary};
      font-weight: 600;
    }
  }
`;

const Actions = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.sm};
  margin-top: ${({ theme }) => theme.spacing.md};
`;

const ActionBtn = styled.button`
  padding: ${({ theme }) => theme.spacing.sm};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  border: none;
  background: none;
  color: ${({ theme, variant }) => {
    switch (variant) {
      case 'ship':
        return theme.colors.primary.main;
      case 'edit':
        return theme.colors.secondary.main;
      case 'delete':
        return theme.colors.error;
      default:
        return theme.colors.text.primary;
    }
  }};
  transition: ${({ theme }) => theme.transitions.default};

  &:hover {
    background-color: ${({ theme }) => theme.colors.background.hover};
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

const Badge = styled.span`
  padding: ${({ theme }) => `${theme.spacing.xs} ${theme.spacing.sm}`};
  border-radius: ${({ theme }) => theme.borderRadius.full};
  ${({ theme }) => theme.typography.caption};
  background-color: ${({ status, theme }) => 
    status === 'completed' ? theme.colors.success : theme.colors.background.accent};
  color: ${({ status, theme }) => 
    status === 'completed' ? theme.colors.common.white : theme.colors.text.primary};
`;

const PriorityBadge = styled(Badge)`
  cursor: pointer;
  background-color: ${({ priority, theme }) => {
    switch (priority) {
      case 'urgent':
        return theme.colors.error;
      case 'low':
        return theme.colors.gray[300];
      default:
        return theme.colors.primary.main;
    }
  }};
  color: ${({ theme }) => theme.colors.common.white};
`;

const OrderCard = ({
  order,
  onUpdatePriority,
  onShip,
  onEdit,
  onDelete
}) => {
  return (
    <Card>
      <Grid>
        <Section>
          <Header>
            <h4>{order.customer_name}</h4>
            {order.is_completed ? (
              <Badge status="completed">Completed</Badge>
            ) : (
              <PriorityBadge 
                priority={order.priority || "medium"}
                onClick={() => onUpdatePriority(order.id, order.priority === 'urgent' ? 'normal' : 'urgent')}
              >
                {order.priority || "Medium"} Priority
              </PriorityBadge>
            )}
          </Header>
          
          <Details>
            <p>
              <Calendar />
              Due: {order.due_date || "Not set"}
            </p>
            <p>
              <Box />
              Type: {order.tomatoType} - {order.tomatoOption}
            </p>
            <p>
              <Package />
              Box: {order.boxType}
            </p>
            <p>
              <Palette />
              Pallet Type: {order.palletType}
            </p>
            {order.additional_info && (
              <p>
                <AlertCircle />
                Additional Info: {order.additional_info}
              </p>
            )}
          </Details>
        </Section>

        <ProgressSection>
          <div>
            <p>Order Progress</p>
            <Progress>
              <div style={{ width: `${order.colli_completion || 0}%` }} />
            </Progress>
          </div>

          <Metrics>
            <div>
              <p>Order Size</p>
              <p>{order.total_pallets} pallets</p>
            </div>
            <div>
              <p>Input Pallets</p>
              <p>{order.assigned_pallets || 0}</p>
            </div>
            <div>
              <p>Output Colli</p>
              <p>{order.completed_colli || 0} / {order.output_colli || 0}</p>
            </div>
            {order.extra_boxes !== null && (
              <div>
                <p>Extra Boxes</p>
                <p>{order.extra_boxes}</p>
              </div>
            )}
          </Metrics>

          {!order.is_completed && (
            <Actions>
              <ActionBtn
                variant="ship"
                onClick={() => onShip(order.id)}
                title="Ship Order"
              >
                <Truck />
              </ActionBtn>
              <ActionBtn
                variant="edit"
                onClick={() => onEdit(order)}
                title="Edit Order"
              >
                <Edit />
              </ActionBtn>
              <ActionBtn
                variant="delete"
                onClick={() => onDelete(order.id)}
                title="Delete Order"
              >
                <X />
              </ActionBtn>
            </Actions>
          )}
        </ProgressSection>
      </Grid>
    </Card>
  );
};

export default OrderCard;
