import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './styles/GlobalStyle';
import { theme } from './styles/theme';
import { ToastProvider, LoadingProvider, ProcessingProvider, UserProvider } from '@lib/contexts';
import { LoginDesktop } from './features/auth/components/LoginDesktop';
import { LoginMobile } from './features/auth/components/LoginMobile';
import MainDashboard from './features/dashboard/components/MainDashboard';
import { InventoryDashboard } from './features/dashboard/components/InventoryDashboard';
import { Dashboard } from './features/operator';

const App = () => {
  const isAuthenticated = () => {
    return !!localStorage.getItem('token');
  };

  const getLastLocation = () => {
    return localStorage.getItem('lastLocation') || '/dashboard';
  };

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <ToastProvider>
        <LoadingProvider>
          <ProcessingProvider>
            <UserProvider>
              <BrowserRouter>
                <Routes>
                  {/* Login Routes */}
                  <Route 
                    path="/login" 
                    element={isMobile ? <LoginMobile /> : <LoginDesktop />} 
                  />

                  {/* Dashboard Routes */}
                  <Route path="/dashboard" element={
                    isAuthenticated() ? (
                      isMobile ? (
                        <Navigate to="/operator" replace />
                      ) : (
                        <MainDashboard />
                      )
                    ) : (
                      <Navigate to="/login" replace />
                    )
                  } />
                  
                  <Route path="/dashboard/inventory" element={
                    isAuthenticated() ? (
                      isMobile ? (
                        <Navigate to="/operator" replace />
                      ) : (
                        <InventoryDashboard />
                      )
                    ) : (
                      <Navigate to="/login" replace />
                    )
                  } />

                  {/* Operator Route */}
                  <Route
                    path="/operator"
                    element={
                      isAuthenticated() ? (
                        isMobile ? (
                          <Dashboard />
                        ) : (
                          <Navigate to="/dashboard" replace />
                        )
                      ) : (
                        <Navigate to="/login" replace />
                      )
                    }
                  />

                  {/* Root Route */}
                  <Route
                    path="/"
                    element={
                      isAuthenticated() ? (
                        <Navigate to={getLastLocation()} replace />
                      ) : (
                        <Navigate to="/login" replace />
                      )
                    }
                  />
                </Routes>
              </BrowserRouter>
            </UserProvider>
          </ProcessingProvider>
        </LoadingProvider>
      </ToastProvider>
    </ThemeProvider>
  );
};

export default App;
