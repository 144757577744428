import { api } from '@api';
import { handleApiError } from '@utils/errorHandler';

const TOKEN_KEY = 'token';
const USER_DATA_KEY = 'userData';

// Atomic token operations
const setTokens = (token, userData) => {
  try {
    localStorage.setItem(TOKEN_KEY, token);
    localStorage.setItem(USER_DATA_KEY, JSON.stringify(userData));
    return true;
  } catch (error) {
    console.error('Error setting tokens:', error);
    return false;
  }
};

const clearTokens = () => {
  try {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(USER_DATA_KEY);
    return true;
  } catch (error) {
    console.error('Error clearing tokens:', error);
    return false;
  }
};

export const loginUser = async (username, password) => {
  try {
    console.log('Making login request...');
    const response = await api.post('/api/users/login', { username, password });
    console.log('Login response:', response);
    
    const { token, user } = response.data.data;
    if (!token) {
      console.warn('No token in response:', response.data);
      throw new Error('Invalid server response: missing token');
    }

    const userData = {
      id: user.id,
      username: user.username,
      // Add any other necessary user fields
    };

    const tokensSaved = setTokens(token, userData);
    if (!tokensSaved) {
      throw new Error('Failed to save authentication data');
    }
    
    return response.data.data;
  } catch (error) {
    console.error('Login error in service:', error);
    clearTokens(); // Clear any partial data on error
    throw handleApiError(error);
  }
};

export const logout = () => {
  clearTokens();
  // Use replace to ensure clean navigation
  window.location.replace('/login');
};

// Add a function to check if tokens exist and are valid
export const hasValidTokens = () => {
  try {
    const token = localStorage.getItem(TOKEN_KEY);
    const userData = localStorage.getItem(USER_DATA_KEY);
    return !!(token && userData);
  } catch (error) {
    console.error('Error checking tokens:', error);
    return false;
  }
};
