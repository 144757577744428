import {
  boxType1Img,
  boxType2Img,
  boxType3Img,
  boxType4Img,
  boxType5Img,
  boxType6Img,
  boxType7Img,
  boxType8Img,
  boxType9Img,
  boxType10Img,
} from '@assets/boxes';

export const boxTypes = [
  {
    type: 'Nexture',
    img: boxType1Img,
  },
  {
    type: 'Van Gelder Low',
    img: boxType2Img,
  },
  {
    type: 'Van Gelder High',
    img: boxType3Img,
  },
  {
    type: 'Black Crate Small',
    img: boxType4Img,
  },
  {
    type: 'Green Crate Large',
    img: boxType5Img,
  },
  {
    type: 'Black Box',
    img: boxType6Img,
  },
  {
    type: 'Royal Red',
    img: boxType7Img,
  },
  {
    type: 'Bella Tondo',
    img: boxType8Img,
  },
  {
    type: 'Green Crate Small',
    img: boxType9Img,
  },
  {
    type: 'Novy',
    img: boxType10Img,
  },
];
