import {
  palletType1Img,
  palletType2Img,
  palletType3Img,
  palletType4Img,
} from '@assets/pallets';

export const palletTypes = [
  {
    type: 'Euro Pallet',
    img: palletType1Img,
  },
  {
    type: 'Pool Pallet',
    img: palletType2Img,
  },
  {
    type: 'Chep Pallet',
    img: palletType3Img,
  },
  {
    type: 'Light Pallet',
    img: palletType4Img,
  },
];
