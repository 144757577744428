import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Loader } from 'lucide-react';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const slideIn = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const ProcessingOverlay = styled.div`
  position: fixed;
  bottom: ${({ theme }) => theme.spacing.xl};
  right: ${({ theme }) => theme.spacing.xl};
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  pointer-events: none;
  z-index: ${({ theme }) => theme.zIndex.toast - 1};
`;

const ProcessingContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.background.paper};
  color: ${({ theme }) => theme.colors.primary.main};
  padding: ${({ theme }) => theme.spacing.lg};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  box-shadow: ${({ theme }) => theme.shadows.lg};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.md};
  min-width: 200px;
  max-width: 400px;
  pointer-events: all;
  animation: ${slideIn} 0.3s ease-out;
`;

const IconWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${spin} 1s linear infinite;
`;

const Content = styled.div`
  flex-grow: 1;
  ${({ theme }) => theme.typography.body1};
`;

const Processing = ({ message = 'Processing...' }) => {
  return (
    <ProcessingOverlay>
      <ProcessingContainer>
        <IconWrapper>
          <Loader size={24} />
        </IconWrapper>
        <Content>{message}</Content>
      </ProcessingContainer>
    </ProcessingOverlay>
  );
};

export default Processing;
