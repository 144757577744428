import React, { useState, useCallback } from 'react';
import QrScanner from 'react-qr-scanner';
import styled from 'styled-components';
import { useToast } from '@lib/contexts';
import axios from '@api/axios';

const ScannerContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
`;

const ScannerOverlay = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 4/3;
  background: #000;
  border-radius: 8px;
  overflow: hidden;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const ScannerGuide = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;
  border: 2px solid #fff;
  border-radius: 12px;
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5);
`;

const ErrorMessage = styled.div`
  color: #ff4444;
  text-align: center;
  margin: 20px 0;
  padding: 10px;
  background: rgba(255, 68, 68, 0.1);
  border-radius: 4px;
`;

const QRCodeScanner = ({ onScanSuccess }) => {
  const [error, setError] = useState(null);
  const { showToast } = useToast();

  const handleError = useCallback((err) => {
    console.error('Scanner error:', err);
    setError('Failed to access camera. Please check permissions.');
  }, []);

  const handleScan = useCallback(async (data) => {
    if (data) {
      try {
        // Parse QR code data
        const qrData = JSON.parse(data.text);
        
        // Version check
        if (!qrData.version) {
          throw new Error('Invalid QR code format: missing version');
        }

        // Handle different QR code versions
        switch (qrData.version) {
          case '1.0': {
            // Call backend API to get full pallet details
            const response = await axios.get(`/api/pallets/scan/${qrData.id}`);
            
            if (response.data.success) {
              // Merge scanned data with backend data for quick preview
              const mergedData = {
                ...response.data.data,
                scannedInfo: {
                  timestamp: qrData.timestamp,
                  type: qrData.type,
                  boxType: qrData.boxType,
                  tomatoType: qrData.tomatoType,
                  tomatoOption: qrData.tomatoOption,
                  weight: qrData.weight
                }
              };
              
              showToast('Pallet found', 'success');
              onScanSuccess(mergedData);
            } else {
              showToast('Failed to find pallet', 'error');
            }
            break;
          }
          default:
            throw new Error(`Unsupported QR code version: ${qrData.version}`);
        }
      } catch (error) {
        console.error('Scan processing error:', error);
        showToast(
          error.message.includes('JSON')
            ? 'Invalid QR code format'
            : error.response?.data?.message || 'Failed to process scan',
          'error'
        );
      }
    }
  }, [onScanSuccess, showToast]);

  return (
    <ScannerContainer>
      <ScannerOverlay>
        <QrScanner
          delay={300}
          onError={handleError}
          onScan={handleScan}
          constraints={{
            video: { facingMode: 'environment' }
          }}
          style={{ width: '100%' }}
        />
        <ScannerGuide />
      </ScannerOverlay>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </ScannerContainer>
  );
};

export default QRCodeScanner;
