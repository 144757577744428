export const boxCountOptions = {
  small: {
    min: 0,
    max: 50,
    label: 'Small (0-50 boxes)'
  },
  medium: {
    min: 51,
    max: 100,
    label: 'Medium (51-100 boxes)'
  },
  large: {
    min: 101,
    max: 150,
    label: 'Large (101-150 boxes)'
  },
  extraLarge: {
    min: 151,
    max: 200,
    label: 'Extra Large (151-200 boxes)'
  },
  custom: {
    label: 'Custom Range'
  }
};
