import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { X } from 'lucide-react';

const StyledModalContent = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.background.paper};
  padding: ${({ theme }) => `${theme.spacing.xl} ${theme.spacing.xl} ${theme.spacing.lg}`};
  border-radius: ${({ theme }) => theme.borderRadius.xl};
  box-shadow: ${({ theme }) => theme.shadows.xl};
  width: 100%;
  max-width: ${({ size }) => {
    switch (size) {
      case 'sm':
        return '400px';
      case 'lg':
        return '800px';
      default:
        return '600px';
    }
  }};
  max-height: calc(100vh - ${({ theme }) => theme.spacing.xl} * 2);
  overflow-y: auto;
  margin: auto;

  /* Custom scrollbar styling */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.background.default};
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.background.darker};
    border-radius: 4px;
    
    &:hover {
      background: ${({ theme }) => theme.colors.background.darkest};
    }
  }
`;

const Header = styled.div`
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.colors.background.paper};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.xl};
  padding-bottom: ${({ theme }) => theme.spacing.md};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.light};
  z-index: 1;

  h3 {
    color: ${({ theme }) => theme.colors.text.primary};
    ${({ theme }) => theme.typography.h3};
    margin: 0;
    font-weight: 600;
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing.sm};
  color: ${({ theme }) => theme.colors.text.secondary};
  transition: ${({ theme }) => theme.transitions.fast};
  border-radius: ${({ theme }) => theme.borderRadius.full};
  margin: -${({ theme }) => theme.spacing.sm};
  
  &:hover {
    color: ${({ theme }) => theme.colors.text.primary};
    background-color: ${({ theme }) => theme.colors.background.hover};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.primary.light};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.background.darker};
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

const Content = styled.div`
  color: ${({ theme }) => theme.colors.text.primary};
  position: relative;
  z-index: 0;
`;

const ModalContent = ({ children, title, onClose, size = 'md' }) => {
  return (
    <StyledModalContent size={size}>
      <Header>
        {title && <h3>{title}</h3>}
        <CloseButton onClick={onClose} aria-label="Close modal">
          <X size={24} />
        </CloseButton>
      </Header>
      <Content>{children}</Content>
    </StyledModalContent>
  );
};

export default ModalContent;
