import styled from 'styled-components';

export const TableWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing.lg};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  box-shadow: ${({ theme }) => theme.shadows.sm};
  background-color: ${({ theme }) => theme.colors.background.paper};
  overflow: hidden;
`;

export const StatusBadge = styled.span`
  display: inline-flex;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing.xs} ${theme.spacing.sm}`};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
  font-weight: 500;
  white-space: nowrap;
  background-color: ${({ status, theme }) => {
    switch (status) {
      case 'completed':
      case 'success':
      case 'processed':
      case 'ready_for_shipping':
        return theme.colors.success + '20';
      case 'warning':
      case 'unprocessed':
      case 'quality_check':
        return theme.colors.warning + '20';
      case 'error':
      case 'returned':
        return theme.colors.error + '20';
      case 'info':
      case 'in_processing':
        return theme.colors.info + '20';
      case 'shipped':
        return theme.colors.primary.main + '20';
      case 'assigned':
        return theme.colors.secondary.main + '20';
      default:
        return theme.colors.gray[200];
    }
  }};
  color: ${({ status, theme }) => {
    switch (status) {
      case 'completed':
      case 'success':
      case 'processed':
      case 'ready_for_shipping':
        return theme.colors.success;
      case 'warning':
      case 'unprocessed':
      case 'quality_check':
        return theme.colors.warning;
      case 'error':
      case 'returned':
        return theme.colors.error;
      case 'info':
      case 'in_processing':
        return theme.colors.info;
      case 'shipped':
        return theme.colors.primary.main;
      case 'assigned':
        return theme.colors.secondary.main;
      default:
        return theme.colors.text.secondary;
    }
  }};
`;

export const CheckboxCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  input[type="checkbox"] {
    width: 16px;
    height: 16px;
    margin: 0;
    cursor: pointer;
    border: 2px solid ${({ theme }) => theme.colors.gray[400]};
    border-radius: ${({ theme }) => theme.borderRadius.sm};
    transition: ${({ theme }) => theme.transitions.default};

    &:checked {
      background-color: ${({ theme }) => theme.colors.primary.main};
      border-color: ${({ theme }) => theme.colors.primary.main};
    }

    &:hover:not(:checked) {
      border-color: ${({ theme }) => theme.colors.primary.main};
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.primary.main + '40'};
    }
  }
`;

export const AssignButton = styled.button`
  color: ${({ theme }) => theme.colors.primary.main};
  font-weight: 500;
  padding: ${({ theme }) => theme.spacing.xs} ${({ theme }) => theme.spacing.sm};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  transition: ${({ theme }) => theme.transitions.default};
  border: none;
  background: none;
  cursor: pointer;
  white-space: nowrap;

  &:hover:not(:disabled) {
    background-color: ${({ theme }) => theme.colors.primary.main + '10'};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.primary.main + '40'};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const ActionCell = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.sm};
  justify-content: flex-end;

  button {
    opacity: 0;
    transform: translateX(4px);
    transition: ${({ theme }) => theme.transitions.default};

    &:focus {
      opacity: 1;
      transform: translateX(0);
    }
  }

  tr:hover & button {
    opacity: 1;
    transform: translateX(0);
  }
`;
