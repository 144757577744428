import React from 'react';
import styled from 'styled-components';

const StyledAlertDialog = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 90%;
  width: 450px;
  z-index: 1000;
`;

const StyledAlertDescription = styled.div`
  margin-top: 0.5rem;
  color: ${props => props.theme.colors?.text?.secondary || '#666'};
  font-size: 0.875rem;
  line-height: 1.5;
`;

export const AlertDescription = ({ children, ...rest }) => {
  return <StyledAlertDescription {...rest}>{children}</StyledAlertDescription>;
};

export const AlertDialog = ({ children, ...rest }) => {
  return <StyledAlertDialog {...rest}>{children}</StyledAlertDialog>;
};

export default AlertDialog;
