import React, { useState, useEffect, useMemo } from 'react';
import { useUserSession } from '@features/auth/hooks/useUserSession';
import { useDashboard, DashboardProvider } from '../../contexts/DashboardContext';
import { useProcessing } from '@lib/contexts';
import { useLocationManagement } from '../../hooks/useLocationManagement';
import { useDashboardNavigation } from '../../hooks/useDashboardNavigation';
import { withAsyncBoundary } from '@lib/components/AsyncBoundary';
import { PalletForm } from '@lib/components/PalletForm'; 
import { AddOrder } from '@lib/components/AddOrder';
import OrderAssignmentModal from '../OrderAssignmentModal';
import BatchActionsManager from '../../utils/BatchActionsManager';
import { useDashboardFilters } from '../../hooks/useDashboardFilters';
import { usePalletHandlers } from '../../hooks/usePalletHandlers';
import { useOrderHandlers } from '../../hooks/useOrderHandlers';
import { handleExportExcel, handleRowKeyDown } from '../../utils/DashboardUtilities';
import {  
  fetchPallets,
  fetchOrders,
  fetchOverviewStats,
  updateOrderPriority,
  updateOrderColli,
  updateOrderDueDate
} from '../../services/dashboardService';

import DashboardLayout from '../DashboardLayout';
import DashboardStats from '../DashboardStats';
import { OrderList, PalletList } from './components';
import { AddPallet } from '@lib/components/AddPallet';

const WrappedMainDashboard = ({ onAsyncAction }) => (
  <DashboardProvider>
    <MainDashboard onAsyncAction={onAsyncAction} />
  </DashboardProvider>
);

const MainDashboard = withAsyncBoundary(({ onAsyncAction }) => {
  const { user } = useUserSession();
  const {
    isLoading,
    setIsLoading,
    selectedLocation,
    setSelectedLocation,
    filters,
    setFilters,
    pallets,
    setPallets,
    orders,
    setOrders,
    overviewStats,
    setOverviewStats
  } = useDashboard();
  
  const { activeTab, setActiveTab } = useDashboardNavigation();

  // Reset filters when switching to pallets tab
  useEffect(() => {
    if (activeTab === 'pallets') {
      setFilters({
        tomatoType: '',
        boxType: '',
        palletType: '',
        orderStatus: '',
        palletStatus: '',
        dateRange: '',
        weightRange: '',
        boxStatus: ''
      });
    }
  }, [activeTab, setFilters]);
  const { showProcessing, hideProcessing } = useProcessing();
  
  const [showAddPallet, setShowAddPallet] = useState(false);
  const [selectedPallet, setSelectedPallet] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedTomatoType, setSelectedTomatoType] = useState('');
  const [showAddOrder, setShowAddOrder] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showCompletedOrders, setShowCompletedOrders] = useState(false);
  const [batchOperationLoading, setBatchOperationLoading] = useState(false);
  const [batchOperationError, setBatchOperationError] = useState(null);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [selectedPalletForAssignment, setSelectedPalletForAssignment] = useState(null);

  // Reset modal state when tab changes
  useEffect(() => {
    setShowAddOrder(false);
    setSelectedOrder(null);
  }, [activeTab]);

  // Reset modal state when location changes
  useEffect(() => {
    setShowAddOrder(false);
    setSelectedOrder(null);
  }, [selectedLocation]);

  const {
    filteredAndSortedPallets,
    handleSelectPallet,
    handleSelectAll,
    requestSort,
    selectedPallets,
    selectAllChecked,
    resetSelection
  } = useDashboardFilters({ pallets, filters, isLoading });

  const loadPallets = async (locationId, tomatoTypeFilter = selectedTomatoType) => {
    setIsLoading(true);
    try {
      await onAsyncAction(async () => {
        const palletData = await fetchPallets(locationId, tomatoTypeFilter);
        setPallets(palletData || []);
      });
    } finally {
      setIsLoading(false);
    }
  };

  const loadOrders = async (locationId) => {
    setIsLoading(true);
    try {
      await onAsyncAction(async () => {
        const orderData = await fetchOrders(locationId, showCompletedOrders);
        setOrders(orderData || []);
      });
    } catch (error) {
      console.error('Error loading orders:', error);
      setOrders([]);
    } finally {
      setIsLoading(false);
    }
  };

  const loadOverviewStats = async () => {
    if (!selectedLocation?.id) return;
    
    setIsLoading(true);
    try {
      await onAsyncAction(async () => {
        const stats = await fetchOverviewStats(selectedLocation.id);
        setOverviewStats(stats || {
          totalKgWarehouse: 0,
          totalKgAssigned: 0,
          shippedToday: 0
        });
      });
    } finally {
      setIsLoading(false);
    }
  };

  const {
    handleAddPallet: baseHandleAddPallet,
    handleDeletePallet,
    handlePalletUpdate: baseHandlePalletUpdate,
    handleShipPallet,
    handleSplitPallet,
    handleReturnPallet
  } = usePalletHandlers({
    selectedLocation,
    user,
    loadPallets,
    onAsyncAction
  });

  const {
    handleShipOrder: baseHandleShipOrder,
    handleAddOrder: baseHandleAddOrder,
    handleDeleteOrder: baseHandleDeleteOrder,
    handleAssignOrderSubmit: baseHandleAssignOrderSubmit
  } = useOrderHandlers({
    selectedLocation,
    onAsyncAction,
    loadOrders,
    loadPallets,
    orders,
    pallets,
    setShowAddOrder
  });

  const { locations, setLocations, loadUserLocations } = useLocationManagement(user);

  useEffect(() => {
    if (locations.length > 0 && !selectedLocation) {
      setSelectedLocation(locations[0]);
    }
  }, [locations, selectedLocation, setSelectedLocation]);

  useEffect(() => {
    if (selectedLocation) {
      loadPallets(selectedLocation.id);
      loadOrders(selectedLocation.id);
      loadOverviewStats();
      // Reset modal states when data is reloaded
      setShowAddOrder(false);
      setSelectedOrder(null);
    }
  }, [selectedLocation, showCompletedOrders]);

  const handleAddOrder = async (orderData) => {
    showProcessing({ 
      message: 'Creating new order...', 
      showOverlay: true 
    });
    try {
      await baseHandleAddOrder(orderData);
    } finally {
      hideProcessing();
    }
  };

  const handleDeleteOrder = async (orderId) => {
    showProcessing({ 
      message: 'Deleting order...', 
      showOverlay: true 
    });
    try {
      await baseHandleDeleteOrder(orderId);
    } finally {
      hideProcessing();
    }
  };

  const handleShipOrder = async (orderId) => {
    showProcessing({ 
      message: 'Shipping order...', 
      showOverlay: true 
    });
    try {
      await baseHandleShipOrder(orderId);
    } finally {
      hideProcessing();
    }
  };

  const handleAssignOrder = (palletId) => {
    setSelectedPalletForAssignment({
      id: Array.isArray(palletId) ? palletId : [palletId],
      isBatch: Array.isArray(palletId)
    });
    setShowAssignModal(true);
  };

  const handleAssignOrderSubmit = async (orderId, extraBoxCount) => {
    showProcessing({ 
      message: 'Assigning pallet to order...', 
      showOverlay: true 
    });
    try {
      await baseHandleAssignOrderSubmit(orderId, extraBoxCount);
      setShowAssignModal(false);
    } finally {
      hideProcessing();
    }
  };

  const handleLocationChange = (e) => {
    const selectedId = e.target.value;
    const location = locations.find(loc => loc.id === parseInt(selectedId));
    setSelectedLocation(location);
    if (location) {
      loadPallets(location.id);
    }
  };

  const handleAddPallet = async (palletData) => {
    showProcessing({ 
      message: 'Adding new pallet...', 
      showOverlay: true 
    });
    try {
      await baseHandleAddPallet(palletData);
      setShowAddPallet(false);
    } finally {
      hideProcessing();
    }
  };

  const handlePalletUpdate = async (updatedPalletData) => {
    showProcessing({ 
      message: 'Updating pallet...', 
      showOverlay: true 
    });
    try {
      await baseHandlePalletUpdate(updatedPalletData, selectedPallet);
      setShowAddPallet(false);
      setIsEditMode(false);
      setSelectedPallet(null);
    } finally {
      hideProcessing();
    }
  };

  const handleEditClick = (pallet) => {
    setSelectedPallet(pallet);
    setIsEditMode(true);
    setShowAddPallet(true);
  };

  const handleUpdateOrderPriority = async (orderId, priority) => {
    showProcessing({
      message: 'Updating order priority...',
      showOverlay: true
    });
    try {
      await updateOrderPriority(orderId, priority);
      await loadOrders(selectedLocation.id);
    } finally {
      hideProcessing();
    }
  };

  const handleUpdateOrderDueDate = async (orderId, dueDate) => {
    showProcessing({
      message: 'Updating due date...',
      showOverlay: true
    });
    try {
      await updateOrderDueDate(orderId, dueDate);
      await loadOrders(selectedLocation.id);
    } finally {
      hideProcessing();
    }
  };

  const handleUpdateOrderColli = async (orderId, completedColli) => {
    showProcessing({
      message: 'Updating colli count...',
      showOverlay: true
    });
    try {
      await updateOrderColli(orderId, completedColli);
      await loadOrders(selectedLocation.id);
    } finally {
      hideProcessing();
    }
  };

  const batchActions = BatchActionsManager(
    selectedPallets,
    onAsyncAction,
    () => {
      loadPallets(selectedLocation.id);
      resetSelection();
    }
  );

  const handleBatchOperation = async (operation, message, ...args) => {
    setBatchOperationLoading(true);
    setBatchOperationError(null);
    showProcessing({ 
      message, 
      showOverlay: true 
    });
    
    try {
      const result = await operation(...args);
      if (result !== true) {
        setBatchOperationError(result);
      }
    } finally {
      hideProcessing();
      setBatchOperationLoading(false);
    }
  };

  const handleBatchShip = () => handleBatchOperation(batchActions.handleBatchShip, 'Shipping selected pallets...');
  const handleBatchDelete = () => handleBatchOperation(batchActions.handleBatchDelete, 'Deleting selected pallets...');
  const handleBatchUpdate = (updateData) => handleBatchOperation(batchActions.handleBatchUpdate, 'Updating selected pallets...', updateData);

  const filteredOrders = useMemo(() => {
    if (!Array.isArray(orders)) return [];
    return orders;
  }, [orders]);

  const renderOverview = () => {
    if (isLoading) {
      return <div>Loading...</div>;
    }

    const activeOrdersCount = Array.isArray(orders) 
      ? orders.filter(o => !o?.is_completed).length 
      : 0;

    const todayByType = Array.isArray(pallets)
      ? Object.entries(
          pallets.reduce((acc, pallet) => {
            if (pallet?.addedDate?.startsWith(new Date().toISOString().split('T')[0])) {
              acc[pallet.tomatoType] = (acc[pallet.tomatoType] || 0) + 1;
            }
            return acc;
          }, {})
        ).map(([type, count]) => ({ type, count }))
      : [];

    return (
      <DashboardStats overviewStats={{
        totalPallets: Array.isArray(pallets) ? pallets.length : 0,
        activeOrders: activeOrdersCount,
        totalKgWarehouse: overviewStats?.totalKgWarehouse || 0,
        totalKgAssigned: overviewStats?.totalKgAssigned || 0,
        shippedToday: overviewStats?.shippedToday || 0,
        todayByType
      }} />
    );
  };

  return (
    <DashboardLayout
      selectedLocation={selectedLocation}
      locations={locations}
      onLocationChange={handleLocationChange}
      activeTab={activeTab}
      onTabChange={setActiveTab}
    >
      {activeTab === 'overview' && renderOverview()}
      {activeTab === 'orders' && (
        <OrderList
          orders={filteredOrders}
          showCompletedOrders={showCompletedOrders}
          onToggleCompleted={() => setShowCompletedOrders(!showCompletedOrders)}
          onAddOrder={() => setShowAddOrder(true)}
          onUpdatePriority={handleUpdateOrderPriority}
          onShipOrder={handleShipOrder}
          onEditOrder={(order) => {
            setSelectedOrder(order);
            setShowAddOrder(true);
          }}
          onDeleteOrder={handleDeleteOrder}
        />
      )}
      {activeTab === 'pallets' && (
        <PalletList
          pallets={filteredAndSortedPallets}
          filters={filters}
          onFilterChange={setFilters}
          selectedPallets={selectedPallets}
          selectAllChecked={selectAllChecked}
          onSelectAll={handleSelectAll}
          onSelectPallet={handleSelectPallet}
          onSort={requestSort}
          onShipPallet={handleShipPallet}
          onEditPallet={handleEditClick}
          onDeletePallet={handleDeletePallet}
          onReturnPallet={handleReturnPallet}
          onSplitPallet={handleSplitPallet}
          onAssignOrder={handleAssignOrder}
          onRowKeyDown={(e, palletId) => handleRowKeyDown(e, palletId, handleSelectPallet)}
          onAddPallet={() => setShowAddPallet(true)}
          onExportExcel={() => handleExportExcel(filters, selectedLocation?.id, onAsyncAction)}
          batchOperationLoading={batchOperationLoading}
          batchOperationError={batchOperationError}
          onBatchShip={handleBatchShip}
          onBatchDelete={handleBatchDelete}
          onBatchUpdate={handleBatchUpdate}
        />
      )}

      <AddPallet
        isOpen={showAddPallet}
        onClose={() => {
          setShowAddPallet(false);
          setIsEditMode(false);
          setSelectedPallet(null);
        }}
        onPalletAdded={isEditMode ? handlePalletUpdate : handleAddPallet}
        locationId={selectedLocation?.id}
        tomatoType={selectedTomatoType}
        initialData={selectedPallet}
        isEditMode={isEditMode}
      />

      <AddOrder
        isOpen={showAddOrder}
        onClose={() => {
          setShowAddOrder(false);
          setSelectedOrder(null);
        }}
        onOrderAdded={handleAddOrder}
        locationId={selectedLocation?.id}
        initialData={selectedOrder}
      />

      <OrderAssignmentModal
        isOpen={showAssignModal}
        onClose={() => setShowAssignModal(false)}
        orders={orders.filter(o => !o.is_completed)}
        onAssign={handleAssignOrderSubmit}
        palletDetails={selectedPalletForAssignment}
      />
    </DashboardLayout>
  );
});

export default WrappedMainDashboard;
