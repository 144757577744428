import { useState } from 'react';

export const useDashboardNavigation = () => {
  const [activeTab, setActiveTab] = useState('overview');

  return {
    activeTab,
    setActiveTab,
  };
};
