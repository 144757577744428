export const handleApiError = (error) => {
  if (error.response) {
    const message = error.response.data?.message || 'An error occurred';
    const status = error.response.status;
    
    switch (status) {
      case 401:
        const currentPath = window.location.pathname;
        if (currentPath !== '/login') {
          localStorage.removeItem('token');
          localStorage.removeItem('userData');
          window.location.href = `/login?redirect=${encodeURIComponent(currentPath)}`;
        }
        return new Error('Session expired. Please login again.');
      case 403:
        return new Error('You do not have permission to perform this action');
      case 404:
        return new Error('The requested resource was not found');
      case 422:
        return new Error(message || 'Invalid data provided');
      case 429:
        return new Error('Too many requests. Please try again later');
      default:
        return new Error(message);
    }
  } else if (error.request) {
    return new Error('No response received from server');
  } else {
    return new Error(error.message || 'An unexpected error occurred');
  }
};
