import { useContext } from 'react';
import { UserContext } from '@lib/contexts';

export const useUserSession = () => {
  const { user, setUser } = useContext(UserContext);
  
  const loadUserSession = () => {
    // UserContext already handles the localStorage logic in its provider
    return user;
  };

  return {
    user,
    setUser,
    loadUserSession
  };
};
