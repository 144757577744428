import React, { useState, useEffect } from 'react';
import { useDashboardData, exportToExcel, addPallet, fetchPallets } from '../../services/dashboardService';
import {
  Container,
  Header,
  Title,
  AddButton,
  ContentCard,
  Grid,
  PalletCard
} from './styles';

export const InventoryDashboard = () => {
  const [pallets, setPallets] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadPallets();
  }, []);

  const loadPallets = async () => {
    try {
      setLoading(true);
      const data = await fetchPallets();
      setPallets(data);
    } catch (error) {
      console.error('Error fetching pallets:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddPallet = () => {
    // Add pallet logic - can be expanded with a modal or form
  };

  return (
    <Container>
      <Header>
        <Title>Supply Management</Title>
        <AddButton onClick={handleAddPallet}>
          Add Pallet
        </AddButton>
      </Header>

      {loading ? (
        <div>Loading...</div>
      ) : (
        <ContentCard>
          <Grid>
            {pallets.map(pallet => (
              <PalletCard key={pallet.id}>
                <h3 className="font-medium">{pallet.tomatoType}</h3>
                <p className="text-sm text-gray-600">Box Type: {pallet.boxType}</p>
                <p className="text-sm text-gray-600">Location: {pallet.location}</p>
              </PalletCard>
            ))}
          </Grid>
        </ContentCard>
      )}
    </Container>
  );
};
