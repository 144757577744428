import { useState, useContext } from 'react';
import { UserContext } from '@lib/contexts';
import { loginUser } from '../services';

export const useLogin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { setUser } = useContext(UserContext);

  const handleLogin = async () => {
    if (!username || !password) {
      setError('Please enter both username and password');
      return;
    }

    try {
      setError('');
      setIsLoading(true);
      const response = await loginUser(username, password);
      setUser(response.user);
      
      // Use replace instead of href to ensure clean navigation
      window.location.replace('/dashboard');
    } catch (error) {
      console.error('Login error:', error);
      setError(error.message || 'An error occurred during login');
      setPassword('');
    } finally {
      setIsLoading(false);
    }
  };

  return {
    username,
    setUsername,
    password,
    setPassword,
    error,
    isLoading,
    handleLogin
  };
};

export default useLogin;
