import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.xl};
  background-color: ${({ theme }) => theme.colors.background.default};
  min-height: 100vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.xl};

  h2 {
    font-size: ${({ theme }) => theme.typography.h2.fontSize};
    color: ${({ theme }) => theme.colors.text.primary};
    font-weight: ${({ theme }) => theme.typography.h2.fontWeight};
    margin: 0;
  }
`;

export const PalletList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.md};
`;

export const PalletItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.lg};
  background-color: ${({ theme, disabled }) => 
    disabled ? theme.colors.background.disabled : theme.colors.background.paper};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  box-shadow: ${({ theme }) => theme.shadows.md};
  border: 1px solid ${({ theme }) => theme.colors.border.light};
  opacity: ${({ disabled }) => disabled ? 0.7 : 1};
  transition: ${({ theme }) => theme.transitions.default};

  &:hover {
    transform: ${({ disabled }) => disabled ? 'none' : 'translateY(-2px)'};
    box-shadow: ${({ theme, disabled }) => 
      disabled ? theme.shadows.md : theme.shadows.lg};
  }

  @media (max-width: 480px) {
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.md};
  }
`;

export const PalletInfo = styled.div`
  flex: 1;

  .header {
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.md};
    margin-bottom: ${({ theme }) => theme.spacing.sm};
  }

  h3 {
    font-size: ${({ theme }) => theme.typography.h3.fontSize};
    color: ${({ theme }) => theme.colors.text.primary};
    font-weight: ${({ theme }) => theme.typography.h3.fontWeight};
    margin: 0;
  }

  p {
    font-size: ${({ theme }) => theme.typography.body2.fontSize};
    color: ${({ theme }) => theme.colors.text.secondary};
    margin: ${({ theme }) => `${theme.spacing.xs} 0`};
  }
`;

export const ProcessStageTag = styled.span`
  display: inline-block;
  padding: ${({ theme }) => `${theme.spacing.xs} ${theme.spacing.sm}`};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  font-size: ${({ theme }) => theme.typography.caption.fontSize};
  font-weight: 500;
  text-transform: uppercase;
  
  ${({ color, theme }) => {
    switch (color) {
      case 'yellow':
        return `
          background-color: #FEF9C3;
          color: #854D0E;
        `;
      case 'orange':
        return `
          background-color: #FFEDD5;
          color: #9A3412;
        `;
      case 'red':
        return `
          background-color: #FEE2E2;
          color: #991B1B;
        `;
      default:
        return `
          background-color: ${theme.colors.background.hover};
          color: ${theme.colors.text.secondary};
        `;
    }
  }}
`;

export const FinishButton = styled.button`
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.lg}`};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  font-size: ${({ theme }) => theme.typography.body1.fontSize};
  font-weight: 500;
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  transition: ${({ theme }) => theme.transitions.default};
  border: none;
  
  ${({ isFinished, theme }) => 
    isFinished 
      ? `
        background-color: ${theme.colors.success}20;
        color: ${theme.colors.success};
      `
      : `
        background-color: ${theme.colors.primary.main};
        color: ${theme.colors.text.inverse};
        
        &:hover:not(:disabled) {
          background-color: ${theme.colors.primary.light};
          transform: translateY(-1px);
        }
        
        &:active:not(:disabled) {
          transform: translateY(0);
        }
      `
  }

  &:disabled {
    opacity: 0.7;
    background-color: ${({ theme }) => theme.colors.background.disabled};
    color: ${({ theme }) => theme.colors.text.disabled};
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const NoDataMessage = styled.div`
  text-align: center;
  padding: ${({ theme }) => theme.spacing.xl};
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: ${({ theme }) => theme.typography.body1.fontSize};
  background-color: ${({ theme }) => theme.colors.background.paper};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  border: 1px solid ${({ theme }) => theme.colors.border.light};
`;
