import { api } from '@lib/api';

/**
 * Handles exporting pallet data to Excel
 * @param {Object} filters - The current filter state
 * @param {string} locationId - Current location ID
 * @param {Function} onAsyncAction - Async action wrapper for handling loading states
 * @returns {Promise<void>}
 */
export const handleExportExcel = async (filters, locationId, onAsyncAction) => {
  return onAsyncAction(
    async () => {
      if (!locationId) {
        throw new Error('Location ID is required for export');
      }

      // Validate filters and include locationId
      const validFilters = Object.entries({
        ...filters,
        locationId
      }).reduce((acc, [key, value]) => {
        if (value !== undefined && value !== null && value !== '') {
          acc[key] = value;
        }
        return acc;
      }, {});

      // Build query parameters
      const queryParams = new URLSearchParams(validFilters);

      try {
        // Request with timeout
        const response = await api.get(
          `/api/pallets/export/excel?${queryParams.toString()}`,
          { 
            responseType: 'blob',
            timeout: 30000, // 30 second timeout
            headers: {
              'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }
          }
        );

        // Validate response
        if (!(response.data instanceof Blob)) {
          throw new Error('Invalid response format');
        }

        if (response.data.size === 0) {
          throw new Error('No data available for export');
        }

        // Create and trigger download
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        const timestamp = new Date().toISOString().split('T')[0];
        const filterSummary = Object.entries(validFilters)
          .map(([key, value]) => `${key}-${value}`)
          .join('_');
        
        link.href = url;
        link.setAttribute(
          'download', 
          `pallets_${filterSummary ? filterSummary + '_' : ''}${timestamp}.xlsx`
        );
        
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);

        return 'Export completed successfully';
      } catch (error) {
        if (error.code === 'ECONNABORTED') {
          throw new Error('Export timed out. Please try again.');
        }
        
        if (error.response) {
          switch (error.response.status) {
            case 404:
              throw new Error('No data found matching the selected filters');
            case 400:
              throw new Error('Invalid filter parameters');
            default:
              throw new Error(`Export failed: ${error.response.data.message || 'Unknown error'}`);
          }
        }
        
        throw new Error('Failed to export data. Please try again.');
      }
    },
    'Preparing export...'
  );
};

/**
 * Handles keyboard events for row selection
 * @param {Event} e - The keyboard event
 * @param {string|number} palletId - The ID of the pallet
 * @param {Function} onSelect - Callback function to handle selection
 */
export const handleRowKeyDown = (e, palletId, onSelect) => {
  if (e.key === 'Enter' || e.key === ' ') {
    e.preventDefault(); // Prevent page scroll on space
    onSelect(palletId);
  }
};
