import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 1rem;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;

  h2 {
    margin: 0;
    color: ${({ theme }) => theme.colors.text};
    font-size: 1.5rem;
  }
`;

export const MapGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
  gap: 0.5rem;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const MapCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  padding: 0.5rem;
  border-radius: 4px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme, type }) => {
    switch (type) {
      case 'light':
        return theme.colors.success;
      case 'middle':
        return theme.colors.warning;
      case 'red':
        return theme.colors.error;
      default:
        return theme.colors.backgroundLight;
    }
  }};
  opacity: ${({ isEmpty }) => isEmpty ? 0.5 : 1};
`;

export const Legend = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const LegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const LegendColor = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background-color: ${({ theme, type }) => {
    switch (type) {
      case 'light':
        return theme.colors.success;
      case 'middle':
        return theme.colors.warning;
      case 'red':
        return theme.colors.error;
      default:
        return theme.colors.backgroundLight;
    }
  }};
`;

export const LegendLabel = styled.span`
  color: ${({ theme }) => theme.colors.text};
  font-size: 0.9rem;
`;

export const LoadingMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: ${({ theme }) => theme.colors.textLight};
  font-size: 1.1rem;
`;

export const ErrorMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: ${({ theme }) => theme.colors.error};
  font-size: 1.1rem;
`;
