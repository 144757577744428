import React, { useState } from 'react';
import { useUserSession } from '@features/auth/hooks/useUserSession';
import { useLocationManagement } from '@features/dashboard/hooks/useLocationManagement';
import { withAsyncBoundary } from '@lib/components/AsyncBoundary';
import { Container, Navigation, NavButton, ContentArea } from './styles';
import { SortingConsole } from '../Sorting/SortingConsole/index';
import { KleinpakConsole } from '../Kleinpak/KleinpakConsole/index';

const WrappedDashboard = ({ onAsyncAction }) => (
  <Dashboard onAsyncAction={onAsyncAction} />
);

const Dashboard = withAsyncBoundary(({ onAsyncAction }) => {
  const { user } = useUserSession();
  const { locations, loadUserLocations } = useLocationManagement(user);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [activeSection, setActiveSection] = useState('sorting');

  // Load locations if not already loaded
  React.useEffect(() => {
    if (user && locations.length === 0) {
      loadUserLocations();
    }
  }, [user, locations.length, loadUserLocations]);

  // Set first location as selected if only one location available
  React.useEffect(() => {
    if (locations.length === 1 && !selectedLocation) {
      setSelectedLocation(locations[0]);
    }
  }, [locations, selectedLocation]);

  const handleLocationChange = (e) => {
    const locationId = parseInt(e.target.value);
    const location = locations.find(loc => loc.id === locationId);
    setSelectedLocation(location);
  };

  const renderContent = () => {
    if (!selectedLocation) {
      return (
        <div style={{ padding: '20px' }}>
          <h2>Select Location</h2>
          <select 
            value={selectedLocation?.id || ''} 
            onChange={handleLocationChange}
            style={{
              padding: '8px',
              fontSize: '16px',
              width: '100%',
              maxWidth: '300px',
              marginTop: '10px'
            }}
          >
            <option value="">Select a location...</option>
            {locations.map(location => (
              <option key={location.id} value={location.id}>
                {location.name}
              </option>
            ))}
          </select>
        </div>
      );
    }

    switch (activeSection) {
      case 'sorting':
        return <SortingConsole locationId={selectedLocation.id} userId={user.id} />;
      case 'kleinpak':
        return <KleinpakConsole locationId={selectedLocation.id} userId={user.id} />;
      default:
        return null;
    }
  };

  if (!user) {
    return <div>Loading user data...</div>;
  }

  return (
    <Container>
      {selectedLocation && (
        <>
          <div style={{ padding: '10px', backgroundColor: '#f5f5f5' }}>
            <select 
              value={selectedLocation.id} 
              onChange={handleLocationChange}
              style={{
                padding: '8px',
                fontSize: '14px',
                width: '100%',
                maxWidth: '200px'
              }}
            >
              {locations.map(location => (
                <option key={location.id} value={location.id}>
                  {location.name}
                </option>
              ))}
            </select>
          </div>
          <Navigation>
            <NavButton
              active={activeSection === 'sorting'}
              onClick={() => setActiveSection('sorting')}
            >
              Sorting
            </NavButton>
            <NavButton
              active={activeSection === 'kleinpak'}
              onClick={() => setActiveSection('kleinpak')}
            >
              Kleinpak
            </NavButton>
          </Navigation>
        </>
      )}
      <ContentArea>
        {renderContent()}
      </ContentArea>
    </Container>
  );
});

export default WrappedDashboard;
