import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledTableRow = styled.tr`
  background-color: ${({ selected, theme }) => 
    selected ? theme.colors.primary.main + '10' : theme.colors.background.paper};
  transition: ${({ theme }) => theme.transitions.default};
  
  &:hover {
    background-color: ${({ selected, theme }) => 
      selected ? theme.colors.primary.main + '20' : theme.colors.background.hover};
  }

  &:focus {
    outline: none;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      inset: 0;
      border: 2px solid ${({ theme }) => theme.colors.primary.main};
      pointer-events: none;
    }
  }

  &:focus:not(:focus-visible) {
    &::after {
      display: none;
    }
  }

  td {
    padding: ${({ theme }) => theme.spacing.md};
    border-bottom: 1px solid ${({ theme }) => theme.colors.border.light};
    transition: ${({ theme }) => theme.transitions.default};
  }

  &:last-child td {
    border-bottom: none;
  }

  &[data-selected="true"] {
    td {
      background-color: ${({ theme }) => theme.colors.primary.main + '10'};
    }

    &:hover td {
      background-color: ${({ theme }) => theme.colors.primary.main + '20'};
    }
  }
`;

const TableRow = ({ 
  children, 
  selected, 
  onKeyDown,
  className,
  tabIndex,
  onClick,
  style
}) => {
  return (
    <StyledTableRow
      selected={selected}
      onKeyDown={onKeyDown}
      onClick={onClick}
      className={className}
      tabIndex={tabIndex}
      data-selected={selected}
      role="row"
      style={style}
    >
      {children}
    </StyledTableRow>
  );
};

TableRow.propTypes = {
  children: PropTypes.node.isRequired,
  selected: PropTypes.bool,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  style: PropTypes.object
};

TableRow.defaultProps = {
  selected: false,
  tabIndex: 0
};

export default TableRow;
