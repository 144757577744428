import { api } from '@lib/api';

export const usePalletHandlers = ({
  selectedLocation,
  user,
  loadPallets,
  onAsyncAction
}) => {
  const handleAddPallet = (palletData) => {
    return onAsyncAction(
      async () => {
        await api.post('/api/pallets/add', {
          ...palletData,
          locationId: selectedLocation.id
        });
        await loadPallets(selectedLocation.id);
      },
      'Pallet added successfully'
    );
  };

  const handleDeletePallet = (palletId) => {
    if (window.confirm('Are you sure you want to delete this pallet?')) {
      return onAsyncAction(
        async () => {
          await api.delete(`/api/pallets/remove/${palletId}`);
          await loadPallets(selectedLocation.id);
        },
        'Pallet deleted successfully'
      );
    }
  };

  const handlePalletUpdate = async (updatedPalletData, selectedPallet) => {
    return onAsyncAction(
      async () => {
        const currentUser = user?.username || 'Unknown User';
        const editEntry = {
          editDate: new Date().toISOString(),
          editedBy: currentUser
        };
        
        const updateData = {
          ...updatedPalletData,
          locationId: selectedLocation.id,
          editHistory: [
            ...(selectedPallet.editHistory || []),
            editEntry
          ]
        };
        
        await api.put(`/api/pallets/update/${selectedPallet.id}`, updateData);
        await loadPallets(selectedLocation.id);
      },
      'Pallet updated successfully'
    );
  };

  const handleShipPallet = (palletId) => {
    if (window.confirm('Are you sure you want to mark this pallet as shipped?')) {
      return onAsyncAction(
        async () => {
          await api.put(`/api/pallets/mark-shipped/${palletId}`);
          await loadPallets(selectedLocation.id);
        },
        'Pallet marked as shipped'
      );
    }
  };

  const handleSplitPallet = async (pallet) => {
    const splitColliCount = prompt(`Enter number of colli to split from pallet (max ${pallet.colliCount})`);
    if (!splitColliCount) return;
  
    const keepOrder = window.confirm('Keep order assignment for the split pallet?');
  
    return onAsyncAction(
      async () => {
        await api.post(`/api/pallets/${pallet.id}/split`, {
          splitColliCount: parseInt(splitColliCount),
          keepOrderAssignment: keepOrder
        });
        await loadPallets(selectedLocation.id);
      },
      'Pallet split successfully'
    );
  };
  
  const handleReturnPallet = async (pallet) => {
    if (window.confirm('Return this pallet from production to warehouse?')) {
      return onAsyncAction(
        async () => {
          await api.post(`/api/pallets/${pallet.id}/return`);
          await loadPallets(selectedLocation.id);
        },
        'Pallet returned to warehouse'
      );
    }
  };

  return {
    handleAddPallet,
    handleDeletePallet,
    handlePalletUpdate,
    handleShipPallet,
    handleSplitPallet,
    handleReturnPallet
  };
};
