import React, { useState } from 'react';
import { X, Filter } from 'lucide-react';
import { theme } from '@styles/theme';
import {
  tomatoTypes,
  orderStatuses,
  palletStatuses,
  dateRangeOptions,
  weightRangeOptions,
  boxCountOptions
} from '@constants';
import { getPalletTypeOptions, getBoxTypeOptions } from '@lib/utils';
import {
  FilterSection,
  FilterGrid,
  FilterSelect,
  FilterHeader,
  FilterTitle,
  ClearFiltersButton
} from './styles';

const DashboardFilters = ({ filters, onChange }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClearFilters = () => {
    onChange({
      tomatoType: '',
      boxType: '',
      palletType: '',
      orderStatus: '',
      palletStatus: '',
      dateRange: '',
      weightRange: '',
      boxStatus: ''
    });
  };

  const activeFiltersCount = Object.values(filters).filter(value => value !== '').length;

  return (
    <FilterSection>
      <FilterHeader>
        <FilterTitle>
          <Filter size={16} style={{ marginRight: theme.spacing.sm }} />
          Filters {activeFiltersCount > 0 && `(${activeFiltersCount})`}
        </FilterTitle>
        {activeFiltersCount > 0 && (
          <ClearFiltersButton onClick={handleClearFilters}>
            <X size={16} />
            Clear Filters
          </ClearFiltersButton>
        )}
      </FilterHeader>
      <FilterGrid>
        <FilterSelect
          value={filters.tomatoType}
          onChange={(e) => onChange({...filters, tomatoType: e.target.value})}
        >
          <option value="">All Tomato Types</option>
          {tomatoTypes.map(type => (
            <option key={type} value={type}>{type}</option>
          ))}
        </FilterSelect>

        <FilterSelect
          value={filters.boxType}
          onChange={(e) => onChange({...filters, boxType: e.target.value})}
        >
          <option value="">All Box Types</option>
          {getBoxTypeOptions().map(type => (
            <option key={type} value={type}>{type}</option>
          ))}
        </FilterSelect>

        <FilterSelect
          value={filters.palletType}
          onChange={(e) => onChange({...filters, palletType: e.target.value})}
        >
          <option value="">All Pallet Types</option>
          {getPalletTypeOptions().map(type => (
            <option key={type} value={type}>{type}</option>
          ))}
        </FilterSelect>

        <FilterSelect
          value={filters.orderStatus}
          onChange={(e) => onChange({...filters, orderStatus: e.target.value})}
        >
          <option value="">Order Status</option>
          {Object.entries(orderStatuses).map(([value, label]) => (
            <option key={value} value={value}>{label}</option>
          ))}
        </FilterSelect>

        <FilterSelect
          value={filters.palletStatus}
          onChange={(e) => onChange({...filters, palletStatus: e.target.value})}
        >
          <option value="">Pallet Status</option>
          {Object.entries(palletStatuses).map(([value, label]) => (
            <option key={value} value={value}>{label}</option>
          ))}
        </FilterSelect>

        <FilterSelect
          value={filters.dateRange}
          onChange={(e) => onChange({...filters, dateRange: e.target.value})}
        >
          <option value="">Date Range</option>
          {Object.entries(dateRangeOptions).map(([value, label]) => (
            <option key={value} value={value}>{label}</option>
          ))}
        </FilterSelect>

        <FilterSelect
          value={filters.weightRange}
          onChange={(e) => onChange({...filters, weightRange: e.target.value})}
        >
          <option value="">Weight Range</option>
          {Object.entries(weightRangeOptions).map(([key, option]) => (
            <option key={key} value={option.value}>{option.label}</option>
          ))}
        </FilterSelect>

        <FilterSelect
          value={filters.boxStatus}
          onChange={(e) => onChange({...filters, boxStatus: e.target.value})}
        >
          <option value="">Box Status</option>
          {Object.entries(boxCountOptions).map(([value, option]) => (
            <option key={value} value={value}>{option.label}</option>
          ))}
        </FilterSelect>
      </FilterGrid>
    </FilterSection>
  );
};

export default DashboardFilters;
