import React, { createContext, useContext, useState } from 'react';

const DashboardContext = createContext();

const defaultFilters = {
  tomatoType: '',
  boxType: '',
  tomatoOption: '', 
  palletType: '',
  orderStatus: 'all',
  palletStatus: 'active',
  dateRange: 'all',
  addedBy: '',
  weightRange: 'all'
};

const defaultOverviewStats = {
  totalKgWarehouse: 0,
  totalKgAssigned: 0,
  shippedToday: 0
};

export const DashboardProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [filters, setFilters] = useState(defaultFilters);
  const [pallets, setPallets] = useState([]);
  const [orders, setOrders] = useState([]);
  const [overviewStats, setOverviewStats] = useState(defaultOverviewStats);

  const value = {
    isLoading,
    setIsLoading,
    selectedLocation,
    setSelectedLocation,
    filters,
    setFilters,
    pallets,
    setPallets,
    orders,
    setOrders,
    overviewStats,
    setOverviewStats,
  };

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboard = () => {
  const context = useContext(DashboardContext);
  if (context === undefined) {
    throw new Error('useDashboard must be used within a DashboardProvider');
  }
  return context;
};
