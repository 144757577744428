import { api } from '@lib/api';

const BatchActionsManager = (selectedPallets, onAsyncAction, loadPallets) => {
  const validateBatchOperation = () => {
    if (!selectedPallets || selectedPallets.size === 0) {
      return 'No pallets selected for operation';
    }
    return null;
  };

  const handleBatchOperation = async (operation, operationName) => {
    const validationError = validateBatchOperation();
    if (validationError) {
      return {
        success: false,
        error: validationError
      };
    }

    try {
      const result = await operation();
      if (result === true) {
        return {
          success: true
        };
      }
      return {
        success: false,
        error: result
      };
    } catch (error) {
      return {
        success: false,
        error: `Failed to ${operationName}: ${error.message || 'Unknown error occurred'}`
      };
    }
  };

  const handleBatchShip = async () => {
    return handleBatchOperation(async () => {
      try {
        const response = await api.post('/api/pallets/batch/ship', {
          palletIds: Array.from(selectedPallets)
        });

        if (!response.data?.ok) {
          throw new Error(response.data?.message || 'Failed to ship pallets');
        }

        await loadPallets();
        return true;
      } catch (error) {
        if (error.response?.data?.errors) {
          const errorDetails = error.response.data.errors
            .map(err => `Pallet ${err.palletId}: ${err.message}`)
            .join('\n');
          return `Failed to ship some pallets:\n${errorDetails}`;
        }
        throw error;
      }
    }, 'ship pallets');
  };

  const handleBatchDelete = async () => {
    const palletCount = selectedPallets.size;
    const confirm = window.confirm(
      `Are you sure you want to delete ${palletCount} ${palletCount === 1 ? 'pallet' : 'pallets'}? This action cannot be undone.`
    );

    if (!confirm) {
      return {
        success: false,
        error: 'Operation cancelled by user'
      };
    }

    return handleBatchOperation(async () => {
      try {
        const response = await api.post('/api/pallets/batch/delete', {
          palletIds: Array.from(selectedPallets)
        });

        if (!response.data?.ok) {
          throw new Error(response.data?.message || 'Failed to delete pallets');
        }

        await loadPallets();
        return true;
      } catch (error) {
        if (error.response?.data?.errors) {
          const errorDetails = error.response.data.errors
            .map(err => `Pallet ${err.palletId}: ${err.message}`)
            .join('\n');
          return `Failed to delete some pallets:\n${errorDetails}`;
        }
        throw error;
      }
    }, 'delete pallets');
  };

  const handleBatchUpdate = async (updateData) => {
    if (!updateData || Object.keys(updateData).length === 0) {
      return {
        success: false,
        error: 'No update data provided'
      };
    }

    return handleBatchOperation(async () => {
      try {
        // Create a new object with only the fields we want to update
        const cleanUpdateData = {
          palletType: updateData.palletType,
          boxType: updateData.boxType,
          processStage: updateData.processStage,
          tomatoType: updateData.tomatoType,
          tomatoOption: updateData.tomatoOption,
          locationId: updateData.locationId,
          isUnfinished: updateData.isUnfinished,
          layers: updateData.layers,
          additionalBoxes: updateData.additionalBoxes,
          colliCount: updateData.colliCount,
          weight: updateData.weight,
          order_id: updateData.order_id
        };

        // Filter out undefined values
        Object.keys(cleanUpdateData).forEach(key => 
          cleanUpdateData[key] === undefined && delete cleanUpdateData[key]
        );

        const response = await api.post('/api/pallets/batch/update', {
          palletIds: Array.from(selectedPallets),
          updateData: cleanUpdateData
        });

        if (!response.data?.ok) {
          throw new Error(response.data?.message || 'Failed to update pallets');
        }

        await loadPallets();
        return true;
      } catch (error) {
        if (error.response?.data?.errors) {
          const errorDetails = error.response.data.errors
            .map(err => `Pallet ${err.palletId}: ${err.message}`)
            .join('\n');
          return `Failed to update some pallets:\n${errorDetails}`;
        }
        throw error;
      }
    }, 'update pallets');
  };

  const handleBatchAssign = async (orderId) => {
    if (!orderId) {
      return {
        success: false,
        error: 'No order selected for assignment'
      };
    }

    return handleBatchOperation(async () => {
      try {
        const response = await api.post('/api/pallets/batch/assign', {
          palletIds: Array.from(selectedPallets),
          orderId
        });

        if (!response.data?.ok) {
          throw new Error(response.data?.message || 'Failed to assign pallets to order');
        }

        await loadPallets();
        return true;
      } catch (error) {
        if (error.response?.data?.errors) {
          const errorDetails = error.response.data.errors
            .map(err => `Pallet ${err.palletId}: ${err.message}`)
            .join('\n');
          return `Failed to assign some pallets:\n${errorDetails}`;
        }
        throw error;
      }
    }, 'assign pallets to order');
  };

  return {
    handleBatchShip,
    handleBatchDelete,
    handleBatchUpdate,
    handleBatchAssign
  };
};

export default BatchActionsManager;
