import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme } from '@styles/theme';

const StyledWrapper = styled.div`
  margin-bottom: ${theme.spacing.lg};
`;

const StyledLabel = styled.label`
  display: block;
  margin-bottom: ${theme.spacing.sm};
  font-size: ${theme.typography.body2.fontSize};
  color: ${theme.colors.text.secondary};
  font-weight: 500;
`;

const StyledSelect = styled.select`
  width: 100%;
  padding: ${theme.spacing.md};
  border: 1px solid ${theme.colors.gray[300]};
  border-radius: ${theme.borderRadius.md};
  background-color: ${theme.colors.background.paper};
  font-size: ${theme.typography.body1.fontSize};
  color: ${theme.colors.text.primary};
  transition: ${theme.transitions.default};
  appearance: none;
  
  &:focus {
    outline: none;
    border-color: ${theme.colors.primary.main};
    box-shadow: 0 0 0 3px ${theme.colors.primary.light}40;
  }

  &:hover {
    border-color: ${theme.colors.primary.light};
  }

  &:disabled {
    background-color: ${theme.colors.gray[100]};
    color: ${theme.colors.text.disabled};
    cursor: not-allowed;
  }
`;

const LocationSelect = ({ 
  value, 
  onChange, 
  children, 
  label = 'Select Location', 
  disabled = false,
  className 
}) => {
  return (
    <StyledWrapper className={className}>
      <StyledLabel htmlFor="location-select">{label}</StyledLabel>
      <StyledSelect 
        id="location-select"
        value={value} 
        onChange={onChange}
        disabled={disabled}
        aria-label={label}
      >
        {children}
      </StyledSelect>
    </StyledWrapper>
  );
};

LocationSelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string
};

LocationSelect.defaultProps = {
  label: 'Select Location',
  disabled: false,
  className: ''
};

export default LocationSelect;
