import React, { useState, useEffect } from 'react';
import { getMapData } from '@operator/services/operatorService';
import {
  Container,
  Header,
  MapGrid,
  MapCell,
  Legend,
  LegendItem,
  LegendColor,
  LegendLabel,
  LoadingMessage,
  ErrorMessage
} from './styles';

export const Map = ({ locationId, filterRanges }) => {
  const [mapData, setMapData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    loadMapData();
  }, [locationId]);

  const loadMapData = async () => {
    try {
      setLoading(true);
      setError(null);
      const data = await getMapData(locationId);
      setMapData(data);
    } catch (error) {
      console.error('Failed to load map data:', error);
      setError('Failed to load map data');
    } finally {
      setLoading(false);
    }
  };

  const getCellColor = (value) => {
    if (!value) return 'empty';
    if (value <= filterRanges.light.max) return 'light';
    if (value <= filterRanges.middle.max) return 'middle';
    return 'red';
  };

  if (loading) {
    return <LoadingMessage>Loading map...</LoadingMessage>;
  }

  if (error) {
    return <ErrorMessage>{error}</ErrorMessage>;
  }

  if (!mapData) {
    return <ErrorMessage>No map data available</ErrorMessage>;
  }

  return (
    <Container>
      <Header>
        <h2>Location Map</h2>
      </Header>
      <MapGrid>
        {mapData.map((row, rowIndex) => (
          row.map((cell, colIndex) => (
            <MapCell
              key={`${rowIndex}-${colIndex}`}
              type={getCellColor(cell.value)}
              isEmpty={!cell.value}
            >
              {cell.value || ''}
            </MapCell>
          ))
        ))}
      </MapGrid>
      <Legend>
        <LegendItem>
          <LegendColor type="light" />
          <LegendLabel>Light ({filterRanges.light.min}-{filterRanges.light.max})</LegendLabel>
        </LegendItem>
        <LegendItem>
          <LegendColor type="middle" />
          <LegendLabel>Middle ({filterRanges.middle.min}-{filterRanges.middle.max})</LegendLabel>
        </LegendItem>
        <LegendItem>
          <LegendColor type="red" />
          <LegendLabel>Red ({filterRanges.red.min}+)</LegendLabel>
        </LegendItem>
        <LegendItem>
          <LegendColor type="empty" />
          <LegendLabel>Empty</LegendLabel>
        </LegendItem>
      </Legend>
    </Container>
  );
};
