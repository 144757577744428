import { useState } from 'react';

export const useOrderAssignment = ({ onAssign, onClose }) => {
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [extraBoxes, setExtraBoxes] = useState('');

  const handleAssign = () => {
    if (!selectedOrder) return;
    
    const extraBoxCount = extraBoxes === '' ? null : parseInt(extraBoxes);
    onAssign(selectedOrder.id, extraBoxCount);
    onClose();
  };

  const resetForm = () => {
    setSelectedOrder(null);
    setExtraBoxes('');
  };

  return {
    selectedOrder,
    setSelectedOrder,
    extraBoxes,
    setExtraBoxes,
    handleAssign,
    resetForm
  };
};

export default useOrderAssignment;
