import axios from '@api/axios';

export const getFilterRanges = async (locationId) => {
  const response = await axios.get(`/api/locations/${locationId}/filter-ranges`);
  return response.data;
};

export const updateFilterRanges = async (locationId, ranges) => {
  const response = await axios.put(`/api/locations/${locationId}/filter-ranges`, ranges);
  return response.data;
};

export const getMapData = async (locationId) => {
  const response = await axios.get(`/api/locations/${locationId}/map`);
  return response.data;
};

export const getStatistics = async (locationId) => {
  const response = await axios.get(`/api/locations/${locationId}/statistics`);
  return response.data;
};

export const validatePalletData = async (palletData) => {
  try {
    const response = await axios.post('/api/pallets/validate', {
      ...palletData,
      addedDate: new Date().toISOString()
    });
    return response.data;
  } catch (error) {
    if (error.response?.status === 401) {
      throw new Error('Authentication required. Please log in again.');
    }
    throw error;
  }
};

export const getMaxLayers = (palletType, boxType) => {
  // Define maximum layers based on pallet and box type combinations
  const maxLayersMap = {
    'Euro Pallet': {
      'Green Crate Large': 12,
      'Green Crate Small': 15,
      'Black Box': 14,
      'Black Crate Small': 15,
      'Bella Tondo': 13,
      'Nexture': 12,
      'Novy': 12,
      'Royal Red': 13,
      'Van Gelder High': 12,
      'Van Gelder Low': 14
    },
    'Pool Pallet': {
      'Green Crate Large': 12,
      'Green Crate Small': 15,
      'Black Box': 14,
      'Black Crate Small': 15,
      'Bella Tondo': 13,
      'Nexture': 12,
      'Novy': 12,
      'Royal Red': 13,
      'Van Gelder High': 12,
      'Van Gelder Low': 14
    },
    'Chep Pallet': {
      'Green Crate Large': 11,
      'Green Crate Small': 14,
      'Black Box': 13,
      'Black Crate Small': 14,
      'Bella Tondo': 12,
      'Nexture': 11,
      'Novy': 11,
      'Royal Red': 12,
      'Van Gelder High': 11,
      'Van Gelder Low': 13
    },
    'Light Pallet': {
      'Green Crate Large': 10,
      'Green Crate Small': 12,
      'Black Box': 11,
      'Black Crate Small': 12,
      'Bella Tondo': 10,
      'Nexture': 10,
      'Novy': 10,
      'Royal Red': 10,
      'Van Gelder High': 10,
      'Van Gelder Low': 11
    }
  };

  return maxLayersMap[palletType]?.[boxType] || 12; // Default to 12 if combination not found
};

export const calculateTotalBoxes = (palletType, boxType, layers, additionalBoxes = 0) => {
  // Define boxes per layer based on pallet and box type combinations
  const boxesPerLayerMap = {
    'Euro Pallet': {
      'Green Crate Large': 8,
      'Green Crate Small': 10,
      'Black Box': 9,
      'Black Crate Small': 10,
      'Bella Tondo': 8,
      'Nexture': 8,
      'Novy': 8,
      'Royal Red': 8,
      'Van Gelder High': 8,
      'Van Gelder Low': 9
    },
    'Pool Pallet': {
      'Green Crate Large': 8,
      'Green Crate Small': 10,
      'Black Box': 9,
      'Black Crate Small': 10,
      'Bella Tondo': 8,
      'Nexture': 8,
      'Novy': 8,
      'Royal Red': 8,
      'Van Gelder High': 8,
      'Van Gelder Low': 9
    },
    'Chep Pallet': {
      'Green Crate Large': 8,
      'Green Crate Small': 10,
      'Black Box': 9,
      'Black Crate Small': 10,
      'Bella Tondo': 8,
      'Nexture': 8,
      'Novy': 8,
      'Royal Red': 8,
      'Van Gelder High': 8,
      'Van Gelder Low': 9
    },
    'Light Pallet': {
      'Green Crate Large': 8,
      'Green Crate Small': 10,
      'Black Box': 9,
      'Black Crate Small': 10,
      'Bella Tondo': 8,
      'Nexture': 8,
      'Novy': 8,
      'Royal Red': 8,
      'Van Gelder High': 8,
      'Van Gelder Low': 9
    }
  };

  const boxesPerLayer = boxesPerLayerMap[palletType]?.[boxType] || 8; // Default to 8 if combination not found
  return (layers * boxesPerLayer) + (additionalBoxes || 0);
};

export const finishPallet = async (palletData) => {
  try {
    // First validate the pallet data
    await validatePalletData(palletData);

    // Calculate total boxes based on pallet configuration
    const maxLayers = getMaxLayers(palletData.palletType, palletData.boxType);
    const totalBoxes = calculateTotalBoxes(
      palletData.palletType, 
      palletData.boxType, 
      maxLayers
    );

    // Submit the pallet with calculated values
    const response = await axios.post('/api/pallets/finish', {
      ...palletData,
      layers: maxLayers,
      totalBoxes,
      additionalBoxes: 0,
      addedDate: new Date().toISOString()
    });

    return response.data;
  } catch (error) {
    if (error.response?.status === 401) {
      throw new Error('Authentication required. Please log in again.');
    } else if (error.response?.status === 404) {
      throw new Error('The requested resource was not found');
    }
    throw error;
  }
};
