import React, { useState } from 'react';
import { finishPallet } from '@operator/services/operatorService';
import {
  Container,
  Header,
  PalletList,
  PalletItem,
  PalletInfo,
  FinishButton,
  NoDataMessage,
  ProcessStageTag
} from './styles';

export const FinishMenu = ({
  selectedPallets,
  onFinishPallet,
  locationId,
  tomatoType,
  filterRanges,
  recentlyFinished
}) => {
  const [processing, setProcessing] = useState(false);

  const handleFinish = async (position) => {
    if (processing) return;
    
    setProcessing(true);
    try {
      const pallet = selectedPallets[position];
      const processStage = pallet.processStage.toLowerCase();
      
      // Add filter ranges if available for the process stage
      const palletData = {
        ...pallet,
        locationId,
        position,
        tomatoType,
        ...(filterRanges?.[processStage] && {
          filterRangeMin: filterRanges[processStage].min,
          filterRangeMax: filterRanges[processStage].max
        })
      };

      await finishPallet(palletData);
      onFinishPallet(position, true);
    } catch (error) {
      console.error('Failed to finish pallet:', error);
      onFinishPallet(position, false);
    } finally {
      setProcessing(false);
    }
  };

  const isRecentlyFinished = (position) => {
    return position in recentlyFinished;
  };

  const getProcessStageColor = (stage) => {
    switch (stage.toLowerCase()) {
      case 'light':
        return 'yellow';
      case 'middle':
        return 'orange';
      case 'red':
        return 'red';
      default:
        return 'gray';
    }
  };

  const renderPallets = () => {
    const pallets = Object.entries(selectedPallets)
      .filter(([_, pallet]) => pallet.tomatoType === tomatoType);

    if (pallets.length === 0) {
      return (
        <NoDataMessage>
          No {tomatoType.toLowerCase()} pallets selected
        </NoDataMessage>
      );
    }

    return (
      <PalletList>
        {pallets.map(([position, pallet]) => (
          <PalletItem 
            key={position}
            disabled={isRecentlyFinished(position)}
          >
            <PalletInfo>
              <div className="header">
                <h3>{position}</h3>
                <ProcessStageTag color={getProcessStageColor(pallet.processStage)}>
                  {pallet.processStage}
                </ProcessStageTag>
              </div>
              <p>Pallet Type: {pallet.palletType}</p>
              <p>Box Type: {pallet.boxType}</p>
              <p>Tomato Option: {pallet.tomatoOption}</p>
            </PalletInfo>
            <FinishButton
              onClick={() => handleFinish(position)}
              disabled={processing || isRecentlyFinished(position)}
              isFinished={isRecentlyFinished(position)}
            >
              {isRecentlyFinished(position) ? 'Finished' : 'Finish'}
            </FinishButton>
          </PalletItem>
        ))}
      </PalletList>
    );
  };

  return (
    <Container>
      <Header>
        <h2>Finish {tomatoType}</h2>
      </Header>
      {renderPallets()}
    </Container>
  );
};
