import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.xl};
  background-color: ${({ theme }) => theme.colors.background.default};
  min-height: 100vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  h3 {
    font-size: ${({ theme }) => theme.typography.h3.fontSize};
    color: ${({ theme }) => theme.colors.text.primary};
    font-weight: ${({ theme }) => theme.typography.h3.fontWeight};
    text-align: center;
    margin: ${({ theme }) => `${theme.spacing.xl} 0`};
  }

  @media (max-width: 480px) {
    padding: ${({ theme }) => theme.spacing.md};
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.xl};
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.colors.background.default};
  padding: ${({ theme }) => theme.spacing.md} 0;
  z-index: ${({ theme }) => theme.zIndex.header};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.light};

  h2 {
    font-size: ${({ theme }) => theme.typography.h2.fontSize};
    color: ${({ theme }) => theme.colors.text.primary};
    font-weight: ${({ theme }) => theme.typography.h2.fontWeight};
    margin: 0;
  }

  @media (max-width: 480px) {
    padding: ${({ theme }) => `${theme.spacing.sm} 0`};
    margin-bottom: ${({ theme }) => theme.spacing.lg};
  }
`;

export const ToggleButton = styled.button`
  background-color: ${({ theme }) => theme.colors.primary.main};
  color: ${({ theme }) => theme.colors.text.inverse};
  border: none;
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.md}`};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  font-size: ${({ theme }) => theme.typography.body1.fontSize};
  font-weight: 500;
  cursor: pointer;
  transition: ${({ theme }) => theme.transitions.default};
  box-shadow: ${({ theme }) => theme.shadows.sm};

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.light};
    transform: translateY(-1px);
    box-shadow: ${({ theme }) => theme.shadows.md};
  }

  &:active {
    transform: translateY(0);
    box-shadow: ${({ theme }) => theme.shadows.sm};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.primary.lighter};
  }

  @media (max-width: 480px) {
    padding: ${({ theme }) => `${theme.spacing.xs} ${theme.spacing.sm}`};
    font-size: ${({ theme }) => theme.typography.body2.fontSize};
  }
`;

export const SelectionGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${({ theme }) => theme.spacing.md};
  margin-bottom: ${({ theme }) => theme.spacing.xl};

  &:nth-of-type(2) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 480px) {
    gap: ${({ theme }) => theme.spacing.sm};
    margin-bottom: ${({ theme }) => theme.spacing.lg};
  }
`;

export const SelectionCell = styled.button`
  background-color: ${({ theme, isSelected }) => 
    isSelected ? theme.colors.primary.main : theme.colors.background.paper};
  color: ${({ theme, isSelected }) => 
    isSelected ? theme.colors.text.inverse : theme.colors.text.primary};
  border: 2px solid ${({ theme, isSelected }) => 
    isSelected ? theme.colors.primary.main : theme.colors.border.light};
  padding: ${({ theme }) => theme.spacing.md};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: ${({ theme }) => theme.transitions.default};
  box-shadow: ${({ theme, isSelected }) => 
    isSelected ? theme.shadows.md : theme.shadows.sm};
  font-weight: ${({ isSelected }) => isSelected ? 600 : 400};
  text-align: center;
  word-break: break-word;
  line-height: 1.3;

  &:hover {
    background-color: ${({ theme, isSelected }) => 
      isSelected ? theme.colors.primary.light : theme.colors.background.hover};
    border-color: ${({ theme }) => theme.colors.primary.main};
    transform: translateY(-1px);
    box-shadow: ${({ theme }) => theme.shadows.md};
  }

  &:active {
    transform: translateY(0);
    box-shadow: ${({ theme }) => theme.shadows.sm};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.primary.lighter};
  }

  @media (max-width: 480px) {
    min-height: 80px;
    padding: ${({ theme }) => theme.spacing.sm};
    font-size: ${({ theme }) => theme.typography.caption.fontSize};
  }
`;
