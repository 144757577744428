import { useNavigate } from 'react-router-dom';
import { useLogin } from './useLogin';

export const useMobileLogin = () => {
  const navigate = useNavigate();
  const {
    username,
    setUsername,
    password,
    setPassword,
    error,
    isLoading,
    handleLogin: baseHandleLogin
  } = useLogin();

  const handleLogin = async () => {
    await baseHandleLogin();
    navigate('/dashboard');
  };

  return {
    username,
    setUsername,
    password,
    setPassword,
    error,
    isLoading,
    handleLogin
  };
};

export default useMobileLogin;
