import React from 'react';

export const withAsyncBoundary = (WrappedComponent) => {
  return class AsyncBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { error: null };
    }

    static getDerivedStateFromError(error) {
      return { error };
    }

    componentDidCatch(error, errorInfo) {
      console.error('AsyncBoundary caught an error:', error, errorInfo);
    }

    onAsyncAction = async (action, successMessage) => {
      try {
        this.setState({ error: null });
        await action();
        return true;
      } catch (error) {
        this.setState({ error });
        return false;
      }
    };

    render() {
      if (this.state.error) {
        return (
          <div role="alert" style={{ padding: '20px', color: 'red' }}>
            <h2>Something went wrong</h2>
            <details style={{ whiteSpace: 'pre-wrap' }}>
              {this.state.error.toString()}
            </details>
            <button
              onClick={() => this.setState({ error: null })}
              style={{ marginTop: '10px' }}
            >
              Try again
            </button>
          </div>
        );
      }

      return <WrappedComponent {...this.props} onAsyncAction={this.onAsyncAction} />;
    }
  };
};
