import React, { createContext, useContext, useState, useCallback } from 'react';
import { LoadingOverlay } from '@lib/components/ui';

const LoadingContext = createContext(null);

export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(null);

  const showLoading = useCallback((message = 'Processing...') => {
    setLoading(message);
  }, []);

  const hideLoading = useCallback(() => {
    setLoading(null);
  }, []);

  return (
    <LoadingContext.Provider value={{ showLoading, hideLoading }}>
      {children}
      {loading && <LoadingOverlay message={loading} />}
    </LoadingContext.Provider>
  );
};

export const useLoading = () => {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error('useLoading must be used within a LoadingProvider');
  }
  return context;
};
