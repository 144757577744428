import { palletTypes } from '@lib/constants/palletTypes';
import { boxTypes } from '@lib/constants/boxOptions';

/**
 * Get array of pallet type strings for select options
 * @returns {string[]} Array of pallet type strings
 */
export const getPalletTypeOptions = () => {
  return palletTypes.map(pallet => pallet.type);
};

/**
 * Get array of box type strings for select options
 * @returns {string[]} Array of box type strings
 */
export const getBoxTypeOptions = () => {
  return boxTypes.map(box => box.type);
};
