import React, { useEffect } from 'react';
import { Modal, ModalContent } from '@components/Modal';
import { ButtonGroup, CancelButton, AssignButton } from './styles';
import { PalletDetails, OrderSelection, ExtraBoxesForm } from './components';
import { useOrderAssignment } from './hooks';

const OrderAssignmentModal = ({ 
  isOpen, 
  onClose, 
  orders, 
  onAssign,
  palletDetails 
}) => {
  const {
    selectedOrder,
    setSelectedOrder,
    extraBoxes,
    setExtraBoxes,
    handleAssign,
    resetForm
  } = useOrderAssignment({ onAssign, onClose });

  // Reset form when modal is closed
  useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
  }, [isOpen, resetForm]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent 
        title="Assign Pallet to Order" 
        onClose={onClose}
        size="md"
      >
        <PalletDetails pallet={palletDetails} />
        
        <OrderSelection
          orders={orders}
          selectedOrder={selectedOrder}
          onSelectOrder={setSelectedOrder}
        />

        {selectedOrder && (
          <ExtraBoxesForm
            value={extraBoxes}
            onChange={setExtraBoxes}
          />
        )}

        <ButtonGroup>
          <CancelButton onClick={onClose}>
            Cancel
          </CancelButton>
          <AssignButton 
            onClick={handleAssign}
            disabled={!selectedOrder}
          >
            Assign to Order
          </AssignButton>
        </ButtonGroup>
      </ModalContent>
    </Modal>
  );
};

export default OrderAssignmentModal;
