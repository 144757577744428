import { useCallback, useState } from 'react';
import { api } from '@lib/api';
import { useToast } from '@lib/contexts';
import { useLoading } from '@lib/contexts';

export const useOrderHandlers = ({
  selectedLocation,
  onAsyncAction,
  loadOrders,
  loadPallets,
  orders,
  pallets,
  setShowAddOrder
}) => {
  const { showToast } = useToast();
  const { showLoading, hideLoading } = useLoading();
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [selectedPalletForAssignment, setSelectedPalletForAssignment] = useState(null);

  const handleAssignOrder = useCallback(async (palletId) => {
    const activeOrders = orders.filter(order => !order.is_completed);
    
    // Only show the modal if there are active orders
    if (activeOrders.length > 0) {
      setSelectedPalletForAssignment(palletId);
      setShowAssignModal(true);
    } else {
      showToast({
        message: 'No active orders available for assignment. Create a new order first.',
        variant: 'warning'
      });
    }
  }, [selectedLocation, orders, onAsyncAction, loadOrders, loadPallets]);

  const handleAssignOrderSubmit = useCallback(async (orderId, extraBoxCount) => {
    try {
      showLoading('Assigning pallets to order...');
      
      // Check if it's a batch operation or single pallet
      const isBatch = Array.isArray(selectedPalletForAssignment);
      const endpoint = isBatch 
        ? '/api/pallets/batch/assign'
        : `/api/pallets/${selectedPalletForAssignment}/assign`;
      
      const payload = isBatch
        ? { palletIds: selectedPalletForAssignment, orderId }
        : { orderId };

      await api.post(endpoint, payload);

      if (extraBoxCount > 0) {
        const orderPallets = pallets.filter(p => p.order_id === orderId);
        const palletsWithBoxes = orderPallets.filter(p => p.colliCount);
        
        if (palletsWithBoxes.length > 0) {
          // Find the pallet with the most boxes to subtract from
          const targetPallet = palletsWithBoxes.reduce((prev, current) => 
            (prev.colliCount > current.colliCount) ? prev : current
          );

          if (targetPallet.colliCount >= extraBoxCount) {
            await api.put(`/api/pallets/update/${targetPallet.id}`, {
              ...targetPallet,
              colliCount: targetPallet.colliCount - extraBoxCount
            });
          }
        }
      }

      await loadPallets(selectedLocation.id);
      await loadOrders(selectedLocation.id);

      showToast({
        message: extraBoxCount !== null ? 
          'Order assigned and marked as completed' : 
          'Pallets assigned to order successfully',
        variant: 'success'
      });
    } catch (error) {
      showToast({
        message: `Error assigning order: ${error.message}`,
        variant: 'error'
      });
    } finally {
      hideLoading();
      setSelectedPalletForAssignment(null);
      setShowAssignModal(false);
    }
  }, [selectedPalletForAssignment, pallets, showToast, showLoading, hideLoading, loadPallets, loadOrders, selectedLocation]);

  const handleShipOrder = useCallback(async (orderId) => {
    const orderToShip = orders.find(o => o.id === orderId);
    if (!orderToShip) {
      showToast({
        message: 'Error: Order not found',
        variant: 'error'
      });
      return;
    }

    try {
      showLoading('Shipping order...');
      await api.post(`/api/orders/ship/${orderId}`);
      await loadOrders(selectedLocation.id);
      await loadPallets(selectedLocation.id);
      
      showToast({
        message: 'Order shipped successfully',
        variant: 'success'
      });
    } catch (error) {
      showToast({
        message: `Error shipping order: ${error.message}`,
        variant: 'error'
      });
    } finally {
      hideLoading();
    }
  }, [selectedLocation, orders, onAsyncAction, loadOrders, loadPallets]);

  const handleAddOrder = useCallback(async (orderData) => {
    try {
      showLoading('Creating order...');
      await api.post('/api/orders/create', {
        ...orderData,
        locationId: selectedLocation.id
      });
      await loadOrders(selectedLocation.id);
      setShowAddOrder(false);
      
      showToast({
        message: 'Order created successfully',
        variant: 'success'
      });
    } catch (error) {
      showToast({
        message: `Error creating order: ${error.message}`,
        variant: 'error'
      });
    } finally {
      hideLoading();
    }
  }, [selectedLocation, onAsyncAction, loadOrders, setShowAddOrder]);

  const handleDeleteOrder = useCallback(async (orderId) => {
    try {
      showLoading('Deleting order...');
      await api.delete(`/api/orders/delete/${orderId}`);
      await loadOrders(selectedLocation.id);
      
      showToast({
        message: 'Order deleted successfully',
        variant: 'success'
      });
    } catch (error) {
      showToast({
        message: `Error deleting order: ${error.message}`,
        variant: 'error'
      });
    } finally {
      hideLoading();
    }
  }, [selectedLocation, onAsyncAction, loadOrders]);

  const handleCloseAssignModal = useCallback(() => {
    setShowAssignModal(false);
    setSelectedPalletForAssignment(null);
  }, []);

  return {
    handleAssignOrder,
    handleShipOrder,
    handleAddOrder,
    handleDeleteOrder,
    showAssignModal,
    setShowAssignModal: handleCloseAssignModal,
    selectedPalletForAssignment,
    handleAssignOrderSubmit
  };
};
