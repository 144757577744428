import React, { useState, useEffect } from 'react';
import { Check, AlertTriangle } from 'lucide-react';
import { Alert, AlertDescription } from '@lib/components/ui';
import { palletTypes, boxTypes, tomatoOptions } from '@lib/constants';
import styled from 'styled-components';

const StepIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacing.xl};
`;

const StepCircle = styled.div`
  height: 2.5rem;
  width: 2.5rem;
  border-radius: ${({ theme }) => theme.borderRadius.full};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ${({ theme }) => theme.transitions.default};
  border: 2px solid;
  ${({ isActive, isCompleted, theme }) => {
    if (isCompleted) {
      return `
        background-color: ${theme.colors.primary.main};
        border-color: ${theme.colors.primary.main};
        color: ${theme.colors.common.white};
      `;
    }
    if (isActive) {
      return `
        border-color: ${theme.colors.primary.main};
        color: ${theme.colors.primary.main};
      `;
    }
    return `
      border-color: ${theme.colors.gray[300]};
      color: ${theme.colors.gray[500]};
    `;
  }}
`;

const StepTitle = styled.span`
  ${({ theme }) => theme.typography.body2};
  margin-left: ${({ theme }) => theme.spacing.sm};
  color: ${({ isActive, theme }) => 
    isActive ? theme.colors.text.primary : theme.colors.text.secondary};
  display: none;
  @media (min-width: 640px) {
    display: block;
  }
`;

const StepConnector = styled.div`
  flex: 1;
  height: 2px;
  margin: 0 ${({ theme }) => theme.spacing.md};
  background-color: ${({ isCompleted, theme }) => 
    isCompleted ? theme.colors.primary.main : theme.colors.gray[300]};
  transition: ${({ theme }) => theme.transitions.default};
`;

const FormContainer = styled.div`
  max-width: 48rem;
  margin: 0 auto;
  padding: ${({ theme }) => theme.spacing.lg};
  background-color: ${({ theme }) => theme.colors.background.paper};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  box-shadow: ${({ theme }) => theme.shadows.md};
`;

const FormSection = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.xl};

  h2 {
    ${({ theme }) => theme.typography.h2};
    color: ${({ theme }) => theme.colors.text.primary};
    margin-bottom: ${({ theme }) => theme.spacing.md};
  }
`;

const FormGroup = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

const Label = styled.label`
  display: block;
  ${({ theme }) => theme.typography.body2};
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-bottom: ${({ theme }) => theme.spacing.xs};
`;

const Input = styled.input`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.md};
  border: 1px solid ${({ hasError, theme }) => 
    hasError ? theme.colors.error : theme.colors.border.main};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  ${({ theme }) => theme.typography.body1};
  background-color: ${({ theme }) => theme.colors.background.paper};
  transition: ${({ theme }) => theme.transitions.default};

  &:hover {
    border-color: ${({ hasError, theme }) => 
      hasError ? theme.colors.error : theme.colors.primary.light};
  }

  &:focus {
    outline: none;
    border-color: ${({ hasError, theme }) => 
      hasError ? theme.colors.error : theme.colors.primary.main};
    box-shadow: 0 0 0 2px ${({ hasError, theme }) => 
      hasError ? `${theme.colors.error}20` : `${theme.colors.primary.main}20`};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.background.darker};
    cursor: not-allowed;
  }
`;

const Select = styled(Input).attrs({ as: 'select' })`
  cursor: pointer;
`;

const TextArea = styled(Input).attrs({ as: 'textarea' })`
  min-height: 100px;
  resize: vertical;
`;

const ErrorText = styled.p`
  ${({ theme }) => theme.typography.caption};
  color: ${({ theme }) => theme.colors.error};
  margin-top: ${({ theme }) => theme.spacing.xs};
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing.xl};
`;

const Button = styled.button`
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.lg}`};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  transition: ${({ theme }) => theme.transitions.default};
  ${({ theme }) => theme.typography.body2};
  
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  ${({ variant, theme }) => {
    switch (variant) {
      case 'primary':
        return `
          background-color: ${theme.colors.primary.main};
          color: ${theme.colors.common.white};
          &:hover:not(:disabled) {
            background-color: ${theme.colors.primary.dark};
          }
        `;
      case 'secondary':
        return `
          background-color: ${theme.colors.secondary.main};
          color: ${theme.colors.common.white};
          &:hover:not(:disabled) {
            background-color: ${theme.colors.secondary.dark};
          }
        `;
      default:
        return `
          background-color: ${theme.colors.background.paper};
          border: 1px solid ${theme.colors.border.main};
          color: ${theme.colors.text.primary};
          &:hover:not(:disabled) {
            background-color: ${theme.colors.background.hover};
          }
        `;
    }
  }}
`;

export const OrderForm = ({
  onOrderAdded,
  onCancel,
  initialData = null,
  locationId,
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    customer_name: initialData?.customer_name || '',
    palletType: initialData?.palletType || '',
    boxType: initialData?.boxType || '',
    tomatoType: initialData?.tomatoType || '',
    tomatoOption: initialData?.tomatoOption || '',
    total_pallets: initialData?.total_pallets || 1,
    collies_per_pallet: initialData?.collies_per_pallet || 0,
    net_weight: initialData?.net_weight || 0,
    priority: initialData?.priority || 'normal',
    due_date: initialData?.due_date || '',
    notes: initialData?.notes || ''
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState(null);

  const steps = [
    {
      title: 'Customer Details',
      fields: ['customer_name', 'priority', 'due_date']
    },
    {
      title: 'Product Specifications',
      fields: ['tomatoType', 'tomatoOption', 'palletType', 'boxType']
    },
    {
      title: 'Quantity Details',
      fields: ['total_pallets', 'collies_per_pallet', 'net_weight']
    },
    {
      title: 'Additional Information',
      fields: ['notes']
    }
  ];

  const validateStep = (stepIndex) => {
    const stepFields = steps[stepIndex - 1].fields;
    const newErrors = {};

    stepFields.forEach(field => {
      switch (field) {
        case 'customer_name':
          if (!formData.customer_name) {
            newErrors.customer_name = 'Customer name is required';
          }
          break;
        case 'total_pallets':
          if (!formData.total_pallets || formData.total_pallets < 1) {
            newErrors.total_pallets = 'At least 1 pallet is required';
          }
          break;
        case 'collies_per_pallet':
          if (formData.collies_per_pallet < 0) {
            newErrors.collies_per_pallet = 'Collies per pallet cannot be negative';
          }
          break;
        case 'net_weight':
          if (formData.net_weight < 0) {
            newErrors.net_weight = 'Net weight cannot be negative';
          }
          break;
        case 'due_date':
          if (formData.due_date) {
            const dueDate = new Date(formData.due_date);
            if (dueDate < new Date()) {
              newErrors.due_date = 'Due date cannot be in the past';
            }
          }
          break;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    if (validateStep(currentStep)) {
      setCurrentStep(prev => Math.min(prev + 1, steps.length));
    }
  };

  const handlePrevious = () => {
    setCurrentStep(prev => Math.max(prev - 1, 1));
  };

  const handleSubmit = async () => {
    if (!validateStep(currentStep)) {
      return;
    }

    setLoading(true);
    try {
      await onOrderAdded({
        ...formData,
        location_id: locationId,
      });
      setFeedback({
        type: 'success',
        message: 'Order created successfully'
      });
    } catch (error) {
      setFeedback({
        type: 'error',
        message: error.message || 'Failed to create order'
      });
    } finally {
      setLoading(false);
    }
  };

  const renderStepIndicator = () => (
    <StepIndicator>
      {steps.map((step, index) => (
        <React.Fragment key={index}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <StepCircle
              isActive={index + 1 === currentStep}
              isCompleted={index + 1 < currentStep}
            >
              {index + 1 < currentStep ? (
                <Check className="w-4 h-4" />
              ) : (
                <span>{index + 1}</span>
              )}
            </StepCircle>
            <StepTitle isActive={index + 1 === currentStep}>
              {step.title}
            </StepTitle>
          </div>
          {index < steps.length - 1 && (
            <StepConnector isCompleted={index + 1 < currentStep} />
          )}
        </React.Fragment>
      ))}
    </StepIndicator>
  );

  const renderField = (field) => {
    const hasError = !!errors[field];

    switch (field) {
      case 'customer_name':
        return (
          <FormGroup>
            <Label>Customer Name</Label>
            <Input
              type="text"
              value={formData.customer_name}
              onChange={(e) => handleInputChange('customer_name', e.target.value)}
              hasError={hasError}
              placeholder="Enter customer name"
            />
            {errors.customer_name && <ErrorText>{errors.customer_name}</ErrorText>}
          </FormGroup>
        );

      case 'tomatoType':
        return (
          <FormGroup>
            <Label>Tomato Type</Label>
            <Select
              value={formData.tomatoType}
              onChange={(e) => handleInputChange('tomatoType', e.target.value)}
              hasError={hasError}
            >
              <option value="">Select Tomato Type</option>
              {Object.keys(tomatoOptions).map(type => (
                <option key={type} value={type}>{type}</option>
              ))}
            </Select>
            {errors.tomatoType && <ErrorText>{errors.tomatoType}</ErrorText>}
          </FormGroup>
        );

      case 'tomatoOption':
        return (
          <FormGroup>
            <Label>Tomato Option</Label>
            <Select
              value={formData.tomatoOption}
              onChange={(e) => handleInputChange('tomatoOption', e.target.value)}
              hasError={hasError}
              disabled={!formData.tomatoType}
            >
              <option value="">Select Tomato Option</option>
              {Object.keys(tomatoOptions[formData.tomatoType] || {}).map(option => (
                <option key={option} value={option}>{option}</option>
              ))}
            </Select>
            {errors.tomatoOption && <ErrorText>{errors.tomatoOption}</ErrorText>}
          </FormGroup>
        );

      case 'palletType':
        return (
          <FormGroup>
            <Label>Pallet Type</Label>
            <Select
              value={formData.palletType}
              onChange={(e) => handleInputChange('palletType', e.target.value)}
              hasError={hasError}
            >
              <option value="">Select Pallet Type</option>
              {palletTypes.map(({ type }) => (
                <option key={type} value={type}>{type}</option>
              ))}
            </Select>
            {errors.palletType && <ErrorText>{errors.palletType}</ErrorText>}
          </FormGroup>
        );

      case 'boxType':
        return (
          <FormGroup>
            <Label>Box Type</Label>
            <Select
              value={formData.boxType}
              onChange={(e) => handleInputChange('boxType', e.target.value)}
              hasError={hasError}
            >
              <option value="">Select Box Type</option>
              {boxTypes.map(({ type }) => (
                <option key={type} value={type}>{type}</option>
              ))}
            </Select>
            {errors.boxType && <ErrorText>{errors.boxType}</ErrorText>}
          </FormGroup>
        );

      case 'total_pallets':
        return (
          <FormGroup>
            <Label>Total Pallets</Label>
            <Input
              type="number"
              value={formData.total_pallets}
              onChange={(e) => handleInputChange('total_pallets', parseInt(e.target.value))}
              hasError={hasError}
              min="1"
              max="100"
              placeholder="Enter total number of pallets"
            />
            {errors.total_pallets && <ErrorText>{errors.total_pallets}</ErrorText>}
          </FormGroup>
        );

      case 'collies_per_pallet':
        return (
          <FormGroup>
            <Label>Collies per Pallet</Label>
            <Input
              type="number"
              value={formData.collies_per_pallet}
              onChange={(e) => handleInputChange('collies_per_pallet', parseInt(e.target.value))}
              hasError={hasError}
              min="0"
              placeholder="Enter number of collies per pallet"
            />
            {errors.collies_per_pallet && <ErrorText>{errors.collies_per_pallet}</ErrorText>}
          </FormGroup>
        );

      case 'net_weight':
        return (
          <FormGroup>
            <Label>Net Weight (kg)</Label>
            <Input
              type="number"
              value={formData.net_weight}
              onChange={(e) => handleInputChange('net_weight', parseFloat(e.target.value))}
              hasError={hasError}
              min="0"
              step="0.01"
              placeholder="Enter net weight in kilograms"
            />
            {errors.net_weight && <ErrorText>{errors.net_weight}</ErrorText>}
          </FormGroup>
        );

      case 'priority':
        return (
          <FormGroup>
            <Label>Priority</Label>
            <Select
              value={formData.priority}
              onChange={(e) => handleInputChange('priority', e.target.value)}
              hasError={hasError}
            >
              <option value="normal">Normal Priority</option>
              <option value="urgent">Urgent</option>
              <option value="low">Low Priority</option>
            </Select>
            {errors.priority && <ErrorText>{errors.priority}</ErrorText>}
          </FormGroup>
        );

      case 'due_date':
        return (
          <FormGroup>
            <Label>Due Date</Label>
            <Input
              type="date"
              value={formData.due_date}
              onChange={(e) => handleInputChange('due_date', e.target.value)}
              hasError={hasError}
              min={new Date().toISOString().split('T')[0]}
            />
            {errors.due_date && <ErrorText>{errors.due_date}</ErrorText>}
          </FormGroup>
        );

      case 'notes':
        return (
          <FormGroup>
            <Label>Notes</Label>
            <TextArea
              value={formData.notes}
              onChange={(e) => handleInputChange('notes', e.target.value)}
              hasError={hasError}
              placeholder="Enter any additional notes or requirements"
            />
            {errors.notes && <ErrorText>{errors.notes}</ErrorText>}
          </FormGroup>
        );

      default:
        return null;
    }
  };

  const handleInputChange = (name, value) => {
    setFormData(prev => {
      const newData = { ...prev, [name]: value };
      // Clear tomato option when tomato type changes
      if (name === 'tomatoType') {
        newData.tomatoOption = '';
      }
      return newData;
    });
    
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: '' }));
    }
  };

  const renderCurrentStep = () => {
    const currentFields = steps[currentStep - 1].fields;
    return (
      <div>
        {currentFields.map(field => renderField(field))}
      </div>
    );
  };

  return (
    <FormContainer>
      {renderStepIndicator()}

      <FormSection>
        <h2>{steps[currentStep - 1].title}</h2>
        {renderCurrentStep()}
      </FormSection>

      <ButtonGroup>
        <Button
          onClick={handlePrevious}
          disabled={currentStep === 1 || loading}
        >
          Previous
        </Button>

        {currentStep < steps.length ? (
          <Button
            variant="primary"
            onClick={handleNext}
            disabled={loading}
          >
            Next
          </Button>
        ) : (
          <Button
            variant="secondary"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? 'Creating Order...' : 'Create Order'}
          </Button>
        )}
      </ButtonGroup>

      {feedback && (
        <Alert className={`mt-4 ${feedback.type === 'error' ? 'bg-red-50' : 'bg-green-50'}`}>
          {feedback.type === 'error' ? (
            <AlertTriangle className="h-4 w-4" />
          ) : (
            <Check className="h-4 w-4" />
          )}
          <AlertDescription>{feedback.message}</AlertDescription>
        </Alert>
      )}
    </FormContainer>
  );
};

export default OrderForm;
