import React from 'react';
import styled from 'styled-components';
import { PalletForm } from '../PalletForm';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: ${({ theme }) => theme.colors.background.paper};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  padding: ${({ theme }) => theme.spacing.lg};
  width: 100%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  margin: ${({ theme }) => theme.spacing.md};
`;

export const AddPallet = ({
  isOpen,
  onClose,
  onPalletAdded,
  locationId,
  tomatoType,
  initialData,
  isEditMode
}) => {
  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContent>
        <PalletForm
          onPalletAdded={onPalletAdded}
          onCancel={onClose}
          locationId={locationId}
          tomatoType={tomatoType}
          initialData={initialData}
          isEditMode={isEditMode}
        />
      </ModalContent>
    </ModalOverlay>
  );
};
