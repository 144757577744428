import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledTableCell = styled.td`
  padding: ${({ theme }) => theme.spacing.md};
  text-align: ${({ align }) => align};
  vertical-align: middle;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.light};
  transition: ${({ theme }) => theme.transitions.default};
  background-color: inherit;

  &:first-child {
    padding-left: ${({ theme }) => theme.spacing.lg};
  }

  &:last-child {
    padding-right: ${({ theme }) => theme.spacing.lg};
  }

  ${({ width }) => width && `
    width: ${width};
    min-width: ${width};
  `}

  ${({ minWidth }) => minWidth && `
    min-width: ${minWidth};
  `}

  ${({ maxWidth }) => maxWidth && `
    max-width: ${maxWidth};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `}

  ${({ truncate }) => truncate && `
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `}

  ${({ noWrap }) => noWrap && `
    white-space: nowrap;
  `}
`;

const TableCell = ({ 
  children, 
  className, 
  onClick,
  align = 'left',
  width,
  minWidth,
  maxWidth,
  truncate,
  noWrap,
  style
}) => {
  return (
    <StyledTableCell 
      className={className} 
      onClick={onClick}
      align={align}
      width={width}
      minWidth={minWidth}
      maxWidth={maxWidth}
      truncate={truncate}
      noWrap={noWrap}
      style={style}
    >
      {children}
    </StyledTableCell>
  );
};

TableCell.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  align: PropTypes.oneOf(['left', 'center', 'right']),
  width: PropTypes.string,
  minWidth: PropTypes.string,
  maxWidth: PropTypes.string,
  truncate: PropTypes.bool,
  noWrap: PropTypes.bool,
  style: PropTypes.object
};

TableCell.defaultProps = {
  align: 'left',
  truncate: false,
  noWrap: false
};

export default TableCell;
