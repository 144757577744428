export const validatePalletData = (data) => {
  const { palletType, boxType, processStage, tomatoType, tomatoOption } = data;
  return !!(palletType && boxType && processStage && tomatoType && 
    (tomatoOption || data.skipTomatoOption));
};

export const createPalletData = (data) => {
  return {
    palletType: data.palletType,
    boxType: data.boxType,
    processStage: data.processStage,
    tomatoType: data.tomatoType,
    tomatoOption: data.skipTomatoOption ? data.availableOptions[0] : data.tomatoOption,
  };
};

export const loadSavedSelections = (userId) => {
  try {
    const saved = localStorage.getItem(`palletSelections_${userId}`);
    return saved ? JSON.parse(saved) : {};
  } catch (error) {
    console.error('Error loading saved selections:', error);
    return {};
  }
};

export const saveSelections = (userId, selections) => {
  try {
    localStorage.setItem(`palletSelections_${userId}`, JSON.stringify(selections));
  } catch (error) {
    console.error('Error saving selections:', error);
  }
};

export const loadRecentlyFinished = () => {
  try {
    const saved = localStorage.getItem('recentlyFinishedPallets');
    return saved ? JSON.parse(saved) : {};
  } catch (error) {
    console.error('Error loading recently finished pallets:', error);
    return {};
  }
};

export const saveRecentlyFinished = (pallets) => {
  try {
    // Clean up old entries (older than 10 minutes)
    const now = Date.now();
    const cleaned = Object.entries(pallets).reduce((acc, [key, timestamp]) => {
      if (now - timestamp < 600000) { // 10 minutes
        acc[key] = timestamp;
      }
      return acc;
    }, {});
    
    localStorage.setItem('recentlyFinishedPallets', JSON.stringify(cleaned));
  } catch (error) {
    console.error('Error saving recently finished pallets:', error);
  }
};
